import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document, Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import './styles.scss';

Font.register({
    family: 'Raleway',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf',
            fontWeight: 'normal',
        },
        {
            src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCPNLA3JC9c.ttf',
            fontWeight: 'bold',
        },
        {
            src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf',
            fontStyle: 'italic',
        },
    ],
});

const styles = StyleSheet.create({
    className: {
        color: '#54585A',
        fontWeight: 'bold',
        fontSize: 12,
    },
    page: {
        fontFamily: 'Raleway',
        color: '#54585A',
        padding: 15,
        paddingTop: 0,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        flexDirection: 'row',
    },
});

export const Pdf = ({
    lessonNames,
    lessonSubNames,
    columns,
    year,
    week,
    weeklyPlanName,
    className,
}: {
    lessonNames: {
        [key: string]: {
            columnIds: number[];
            lessonIds: number[];
        };
    };
    lessonSubNames: {
        [key: string]: {
            columnIds: number[];
            lessonIds: number[];
        };
    };
    columns: any;
    year: number;
    week: number;
    weeklyPlanName: string | null | undefined;
    className: string;
}) => {
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.page}>
                    <View style={styles.header} fixed>
                        <Text style={styles.className}>{weeklyPlanName}</Text>
                        <Text style={styles.className}>{className}</Text>
                    </View>
                    <View
                        fixed
                        render={({ pageNumber }) => {
                            if (pageNumber === 1) {
                                return <View />;
                            }
                            return (
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginLeft: 19,
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    {[1, 2, 3, 4, 5].map((day) => (
                                        <Text
                                            key={day}
                                            style={{
                                                padding: 5,
                                                backgroundColor: '#007FA3',
                                                color: 'white',
                                                width: 155,
                                                margin: 'auto',
                                                fontSize: 12,
                                                textAlign: 'center',
                                                fontWeight: 600,
                                                marginTop: 3,
                                                height: 25,
                                            }}
                                        >
                                            {moment()
                                                .day(day)
                                                .isoWeekYear(year)
                                                .isoWeek(week)
                                                .format('ddd')
                                                .toUpperCase()}
                                            ,{' '}
                                            {moment()
                                                .day(day)
                                                .isoWeekYear(year)
                                                .isoWeek(week)
                                                .format('D MMM')}
                                        </Text>
                                    ))}
                                </View>
                            );
                        }}
                    />
                    {Object.entries(lessonNames).map(
                        ([name, { columnIds, lessonIds }], lessonIndex: number) => {
                            return (
                                <View key={`${name}`} style={{ paddingLeft: 0 }}>
                                    {Object.entries(lessonSubNames)
                                        .filter(([_, ids]) => {
                                            if (
                                                !ids.columnIds.every((id: number) =>
                                                    columnIds.includes(id),
                                                ) ||
                                                !ids.lessonIds.every((id: number) =>
                                                    lessonIds.includes(id),
                                                )
                                            ) {
                                                return false;
                                            }

                                            return true;
                                        })
                                        .map(([lessonSubName]: [string, unknown], idx) => {
                                            const subName = lessonSubName.split('##')[0];
                                            const rows = {} as { [key: string]: string[][] };
                                            columns.forEach((column: any) => {
                                                column
                                                    .filter(
                                                        ({ content }: { content: string }) =>
                                                            content,
                                                    )
                                                    .forEach((row: any) => {
                                                        if (!rows[row.head]) {
                                                            rows[row.head] = [['', '', '', '', '']];
                                                        }
                                                        if (
                                                            !rows[row.head][rows[row.head].length]
                                                        ) {
                                                            rows[row.head][rows[row.head].length] =
                                                                ['', '', '', '', ''];
                                                        }
                                                        const indexToPush = rows[
                                                            row.head
                                                        ].findIndex(
                                                            (val: string[]) => !val[row.day - 1],
                                                        );
                                                        rows[row.head][indexToPush][row.day - 1] =
                                                            row.content;
                                                    });
                                            });

                                            if (
                                                subName
                                                    .toLocaleLowerCase()
                                                    .includes('teacher note') ||
                                                !Object.keys(rows).length
                                            ) {
                                                return null;
                                            }

                                            const html = `<div
                                                style="
                                                    border-collapse: collapse;
                                                "
                                            >
                                            <div
                                                style="border-left: ${
                                                    !subName
                                                        ? '17px solid #5c3f98'
                                                        : '17px solid #ab1761'
                                                };
                                                    border-top: ${
                                                        !subName ? '5px solid #5c3f98' : ''
                                                    };
                                                    margin-top: ${!subName ? -2 : 0};
                                                    table-layout: fixed;
                                                    padding-left: 3;
                                                    position: relative;
                                                    min-height: ${
                                                        (subName || 'Homework11231').length *
                                                        (!idx ? 6 : 5)
                                                    }
                                                "
                                                class="tablePdf"
                                            >
                                                ${`<div class="subname" style="left: ${
                                                    -(subName.length || 3) * 4 -
                                                    // eslint-disable-next-line no-nested-ternary
                                                    (!idx && subName ? 60 : !subName ? 70 : 60)
                                                }; top: ${!subName ? '-3px' : '2px'}">
                                                        ${subName || 'Homework'}
                                                   </div>`}
                                                <table
                                                    style="border-collapse: collapse; margin-top: -15px;"
                                                >
                                                ${
                                                    !lessonIndex && !idx
                                                        ? `<tr>
                                                            ${[1, 2, 3, 4, 5]
                                                                .map(
                                                                    (day) =>
                                                                        `<th key={day}>
                                                                    <div
                                                                        style="
                                                                            padding: 5;
                                                                            backgroundColor: #007FA3;
                                                                            color: white;
                                                                            width: 155px;
                                                                            margin: auto;
                                                                            font-size: 12px;
                                                                            text-align: center;
                                                                            font-weight: bold;
                                                                            margin-top: 3px;
                                                                        "
                                                                    >
                                                                    ${moment()
                                                                        .day(day)
                                                                        .isoWeekYear(year)
                                                                        .isoWeek(week)
                                                                        .format('ddd')
                                                                        .toUpperCase()}
                                                                    ,${' '}
                                                                    ${moment()
                                                                        .day(day)
                                                                        .isoWeekYear(year)
                                                                        .isoWeek(week)
                                                                        .format('D MMM')}
                                                                    </div>
                                                                </th>`,
                                                                )
                                                                .join('')}
                                                        </tr>`
                                                        : ''
                                                }
                                                    ${
                                                        rows[lessonSubName]
                                                            ? rows[lessonSubName]
                                                                  ?.map((row: string[]) => {
                                                                      if (!row.join('')) {
                                                                          return null;
                                                                      }
                                                                      return `<tr key={rowIndex}>
                                                                    ${row
                                                                        .map(
                                                                            (item: string) =>
                                                                                `<td
                                                                                style="
                                                                                    width: '20%';
                                                                                    padding: 7;
                                                                                "
                                                                                key={item}
                                                                            >  
                                                                                <div>
                                                                                    ${item}
                                                                                </div>
                                                                            </td>`,
                                                                        )
                                                                        .join('')}
                                                                </tr>`;
                                                                  })
                                                                  .join('')
                                                            : ''
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                        <div 
                                        style="
                                            width: 190px,
                                            margin-left: 15px;
                                            padding-left: 15px;
                                            display: flex;
                                            justify-content: flex-end;
                                            height: 3px;
                                        "><div
                                        style="
                                            width: 795px,
                                            display: flex;
                                            justify-content: end;
                                            height: 3px;
                                            border-bottom: ${!subName ? '' : '2px dashed #9e9e9e'};
                                        "></div></div>
                                        <style>
                                        .subname {
                                            letter-spacing: 0;
                                            color: white;
                                            transform: matrix(0, -1, 1, 0, 0, 0);
                                            white-space: nowrap;
                                            display: flex;
                                            justify-content: flex-end;
                                            font-size: 10px;
                                            z-index: 99999999999999;
                                            font-weight: bold;
                                        }
    
                                        .tablePdf table { border: none; border-collapse: collapse; }
                                        .tablePdf table td { border-left: 1px dotted #000; border-bottom: 1px dotted #000; }
                                        .tablePdf table td:last-child { border-right: 1px dotted #000; }
                                        .tablePdf table td div { font-size: 8px; font-weight: bold; display: flex, flex-direction: column; }
                                        .tablePdf table td span {
                                            font-size: 6px;
                                            display: flex;
                                            font-weight: normal;
                                        }
                                        .tablePdf table td:first-child { border-left: none; }
                                        .tablePdf table tr:last-child td { border-bottom: none; }
                                        </style>`;

                                            return (
                                                <View
                                                    key={`${lessonSubName} ${columns.join('')}`}
                                                    wrap={false}
                                                >
                                                    <Html>{html}</Html>
                                                </View>
                                            );
                                        })}
                                </View>
                            );
                        },
                    )}
                </View>
            </Page>
        </Document>
    );
};

import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getClass } from 'src/redux/classes/actions';
import { selectClass } from 'src/redux/classes/selectors';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import './styles.scss';
import WhiteBox from 'src/components/WhiteBox';
import { finishLoading } from 'src/redux/loading/actions';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { putElearning } from 'src/redux/weeklyPlan/actions';
import { appConstants } from 'src/constants';
import NewAssignmentsELearning from './NewAssignmentsELearning';

const NewAssigment = () => {
    const RESOURCE = 'resource';
    const WRITING = 'writing';
    const E_LEARNING = 'e_learning';
    const dispatch = useDispatch();
    const params = useParams();
    const classData = useSelector(selectClass);
    const state = useLocation().state as any;
    const [tab, setTab] = useState(E_LEARNING);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const getIframeSrc = (curTab: any) => {
        switch (curTab) {
            case RESOURCE:
                return `${appConstants.server}/node/add/content_collaborative_page___com?schoolid=1792&embed=2`;
            case WRITING:
                return `${appConstants.server}/administration/writing?embed=2`;
            default:
                return '';
        }
    };

    const redirectBackToHomework = (e: any) => {
        ((curEvent: any) => {
            if (curEvent.data === 'done') {
                const tempState = Object.assign(state ?? {}, {
                    tab: 'assignments',
                    addAssignment: true,
                });
                navigate(`/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}`, {
                    state: tempState,
                });
            }
        }).apply(this, [e]);
    };

    useEffect(() => {
        window.addEventListener('message', redirectBackToHomework);
        dispatch(putElearning({}));
        return () => {
            window.removeEventListener('message', redirectBackToHomework);
        };
    }, []);

    useEffect(() => {
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    useEffect(() => {
        if (params.id && +params.id !== classData?.id) {
            dispatch(
                getClass({
                    classId: params.id,
                    cb: (data: any) => {
                        dispatch(
                            putBreadcrumbs([
                                {
                                    name: `Class ${data.name}`,
                                },
                            ]),
                        );
                    },
                }),
            );
        } else if (params.id && classData?.id) {
            dispatch(
                putBreadcrumbs([
                    {
                        name: `Class ${classData.name}`,
                        link: `/weekly-plan/${params.id}`,
                    },

                    {
                        name: `${moment()
                            .day(+params.day! as unknown as number)
                            .isoWeekYear(+params.year! as unknown as number)
                            .isoWeek(+params.week! as unknown as number)
                            .format('dddd')} Homework`,
                        link: `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}`,
                    },

                    {
                        name: 'Add assignment',
                        link: `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}/assigment`,
                    },
                ]),
            );
        }
    }, [dispatch, params, classData?.id]);
    const { isDesktop } = useWindowSize();
    const getWhiteBoxStyles = () => {
        if (tab === E_LEARNING && !isDesktop) {
            return {
                minHeight: 'unset',
                marginBottom: '-15px',
                borderRadius: 'unset',
                boxShadow: '0px 20vh 0 20vh #fff',
            };
        }
        return {};
    };
    return (
        <div className="newAssisment">
            <span className="newAssisment-title">{t('NewAssigment.addAssignment')}</span>
            <WhiteBox styles={getWhiteBoxStyles()}>
                <div className="newAssisment-tabBar">
                    <div className="newAssisment-tabs">
                        <span
                            className={classNames('newAssisment-tab sc_hover_color_text_svg', {
                                'newAssisment-tab--active': tab === E_LEARNING,
                            })}
                            onClick={() => {
                                dispatch(putElearning({}));
                                setTab(E_LEARNING);
                            }}
                        >
                            {t('NewAssigment.eLearning')}
                        </span>
                        <span
                            className={classNames('newAssisment-tab sc_hover_color_text_svg', {
                                'newAssisment-tab--active': tab === RESOURCE,
                            })}
                            onClick={() => setTab(RESOURCE)}
                        >
                            {t('NewAssigment.resourcePage')}
                        </span>
                        <span
                            className={classNames('newAssisment-tab sc_hover_color_text_svg', {
                                'newAssisment-tab--active': tab === WRITING,
                            })}
                            onClick={() => setTab(WRITING)}
                        >
                            {t('NewAssigment.writing')}
                        </span>
                    </div>
                </div>
                <>
                    {[RESOURCE, WRITING].includes(tab) && (
                        <iframe
                            src={getIframeSrc(tab)}
                            id="iframe"
                            title="writing"
                            className="newAssisment-iFrame"
                            onLoad={() => dispatch(finishLoading())}
                        />
                    )}
                    {tab === E_LEARNING && (
                        <div className="NewAssignmentsEL-parent">
                            <NewAssignmentsELearning />
                        </div>
                    )}
                </>
            </WhiteBox>
        </div>
    );
};

export default NewAssigment;

import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import WhiteBox from 'src/components/WhiteBox';
import './styles.scss';
import Delete from 'src/components/Icons/Delete';
import Edit from 'src/components/Icons/Edit';
import Remove from 'src/components/Icons/Remove';
import Editable from 'src/components/Editable';
import { useOutsideClick } from 'src/hooks/outsideClick';
import Disc from 'src/components/Icons/Disc';
import { selectWeeklyPlan } from 'src/redux/weeklyPlan/selectors';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { updateWeeklyDay } from 'src/redux/weeklyPlan/actions';
import Add from 'src/components/Icons/Add';
import Tooltip from 'src/components/Tooltip';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { getCol } from '../helpers';
import { NotesProps } from './types';

const HomeworkNotes = ({
    currentLesson,
    currentUnits,
    setUpdateLesson,
    setIsDirty,
}: NotesProps) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();
    const [tab, setTab] = useState('homework');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const homeworkData = useMemo(() => getCol(currentLesson?.studentText), [currentLesson]);
    const teacherData = useMemo(() => getCol(currentLesson?.teacherText), [currentLesson]);
    const [edit, setEdit] = useState('');
    const [data, setData] = useState({
        homework: homeworkData,
        note: teacherData,
    } as any);

    const [editData, setEditData] = useState({
        homework: homeworkData,
        note: teacherData,
    } as any);
    const wrapperRef = useRef({});
    useOutsideClick({ current: (wrapperRef as any).current[edit] }, () => setEdit(''));
    const weekly = useSelector(selectWeeklyPlan) as any;
    const params = useParams();
    const [dataToUpdate, setDataToUpdate] = useState(null as any);

    useEffect(() => {
        setData({
            homework: homeworkData,
            note: teacherData,
        });
        setEditData({
            homework: homeworkData,
            note: teacherData,
        });
    }, [homeworkData, teacherData]);
    return (
        <WhiteBox>
            <div className="homeworkNotes-barItem">
                <div className="homeworkNotes-tabBar">
                    <div className="homeworkNotes-tabs">
                        <span
                            className={classNames('homeworkNotes-tab sc_hover_color_text_svg', {
                                'homeworkNotes-tab--active': tab === 'homework',
                            })}
                            onClick={() => setTab('homework')}
                        >
                            {t('homework.homework')}
                            <Tooltip
                                desc={t('homework.homeworkNotes.addOrEditBlockInHomeworkSection')}
                                styles={{ left: '-69px' }}
                            />
                        </span>
                        <span
                            className={classNames('homeworkNotes-tab sc_hover_color_text_svg', {
                                'homeworkNotes-tab--active': tab === 'note',
                            })}
                            onClick={() => setTab('note')}
                        >
                            {t('homework.homeworkNotes.teacherNote')}
                            <Tooltip
                                desc={t('homework.homeworkNotes.teacherNotesViewableByTeachers')}
                                styles={{ left: '-63px' }}
                            />
                        </span>
                    </div>
                    <div
                        className="homeworkNotes-disc pr_hover_color_text_svg_bg"
                        onClick={() => {
                            setIsDirty(false);
                            const toUpdate = JSON.parse(JSON.stringify(weekly));
                            const dayIndex = toUpdate.days.findIndex(
                                ({ day }: any) => day === +params.day!,
                            );
                            const lessonToUpdate = weekly.days[dayIndex].lessons.findIndex(
                                ({ lessonId, columnId }: any) =>
                                    lessonId === currentLesson.lessonId &&
                                    columnId === currentLesson.columnId,
                            );
                            toUpdate.days[dayIndex].lessons[lessonToUpdate] = currentLesson;
                            setDataToUpdate(toUpdate);
                        }}
                    >
                        <Disc />
                        {width > 1400 ? t('homework.homeworkNotes.save') : ''}
                    </div>
                </div>

                <>
                    {tab !== 'resources' && (
                        <div className="weeklyPlan-add">
                            <div
                                className="weeklyPlan-add__svg-parent sc_hover_color_text_svg"
                                onClick={() => {
                                    setIsDirty(true);
                                    const newColumns = JSON.parse(JSON.stringify(data[tab]));
                                    newColumns.splice(0, 0, {
                                        id: uuidv4(),
                                        content: '',
                                    });
                                    setData({
                                        ...data,
                                        [tab]: newColumns,
                                    });
                                    setEditData({
                                        ...data,
                                        [tab]: newColumns,
                                    });
                                }}
                            >
                                <Tooltip desc={t('homework.homeworkNotes.addNewBlock')} />
                                <Add fill="#bcbcbc" />
                            </div>
                        </div>
                    )}

                    {['homework', 'note'].includes(tab) && (
                        <div className="homeworkNotes-draggableItems">
                            {data[tab].map((item: any) => {
                                return (
                                    <div
                                        className={classNames(
                                            'weeklyPlan-draggable homeworkNotes-draggable',
                                        )}
                                        key={item.id}
                                    >
                                        <div
                                            ref={(ref) => {
                                                (wrapperRef as any).current[item.id] = ref;
                                            }}
                                        >
                                            <div
                                                className={classNames(
                                                    'weeklyPlan-draggable-controls',
                                                    {
                                                        'weeklyPlan-draggable-controls--disable':
                                                            edit,
                                                    },
                                                )}
                                            >
                                                <div
                                                    className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                    onClick={() => setEdit(item.id)}
                                                >
                                                    <Edit fill="#54585a" />
                                                </div>
                                                <div
                                                    className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                    onClick={() => {
                                                        setIsDirty(true);
                                                        const newColumns = JSON.parse(
                                                            JSON.stringify(data[tab]),
                                                        );
                                                        const editedItem = (
                                                            newColumns as any
                                                        ).findIndex(({ id }: any) => {
                                                            return id === item.id;
                                                        });
                                                        newColumns.splice(editedItem, 1);
                                                        const updatedData = {
                                                            ...data,
                                                            [tab]: newColumns,
                                                        };
                                                        setData(updatedData);
                                                        setUpdateLesson((dataL: any) => {
                                                            return {
                                                                ...dataL,
                                                                [tab === 'homework'
                                                                    ? 'studentText'
                                                                    : 'teacherText']: `<p>${updatedData[
                                                                    tab
                                                                ]
                                                                    .map(
                                                                        ({ content }: any) =>
                                                                            `<p>${content}</p>`,
                                                                    )
                                                                    .join('')}</p>`,
                                                            };
                                                        });
                                                    }}
                                                >
                                                    <Remove />
                                                </div>
                                            </div>

                                            <Editable
                                                html={
                                                    edit
                                                        ? (editData[tab] as any).find(
                                                              ({ id }: any) => {
                                                                  return id === item.id;
                                                              },
                                                          ).content
                                                        : item.content
                                                }
                                                onChange={(e: any) => {
                                                    const newColumns = JSON.parse(
                                                        JSON.stringify(data[tab]),
                                                    );
                                                    const editedItem = (newColumns as any).find(
                                                        ({ id }: any) => {
                                                            return id === item.id;
                                                        },
                                                    );
                                                    editedItem.content = e.target.value;
                                                    setEditData({
                                                        ...data,
                                                        [tab]: newColumns,
                                                    });
                                                }}
                                                onSave={() => {
                                                    setIsDirty(true);
                                                    setData({
                                                        ...data,
                                                        [tab]: editData[tab].map((d: any) => {
                                                            return {
                                                                ...d,
                                                                content: d.content,
                                                            };
                                                        }),
                                                    });
                                                    setUpdateLesson((dataL: any) => {
                                                        return {
                                                            ...dataL,
                                                            [tab === 'homework'
                                                                ? 'studentText'
                                                                : 'teacherText']: `<p>${editData[
                                                                tab
                                                            ]
                                                                .map(
                                                                    ({ content }: any) =>
                                                                        `<p>${content}</p>`,
                                                                )
                                                                .join('')}</p>`,
                                                        };
                                                    });
                                                    setEdit('');
                                                }}
                                                edit={edit === item.id}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            </div>
            <div className="homeworkNotes-barItem">
                <div className="homeworkNotes-tabBar">
                    <div className="homeworkNotes-tabs homeworkNotes-resource-tabs">
                        <span className={classNames('homeworkNotes-tab')}>
                            {t('homework.homeworkNotes.resources')}
                        </span>
                    </div>
                </div>
                <div className="homeworkNotes-resources homeworkNotes-draggableItems">
                    {currentLesson?.activities.map((activity: any) => {
                        return (
                            <div
                                key={activity.id}
                                className={classNames('homeworkNotes-resource', {
                                    currentlyAdded: activity.currentlyAdded,
                                })}
                            >
                                <span>
                                    <span
                                        className={classNames(
                                            activity.currentlyAdded
                                                ? 'currentlyAdded-circle active'
                                                : 'currentlyAdded-circle',
                                        )}
                                    />
                                    {activity.name}
                                </span>
                                <div
                                    onClick={() => {
                                        setUpdateLesson((dataL: any) => {
                                            return {
                                                ...dataL,
                                                activities: dataL.activities.filter(
                                                    (act: any) => act.id !== activity.id,
                                                ),
                                            };
                                        });
                                    }}
                                    className="classes-delete sc_hover_color_text_svg"
                                >
                                    <Delete />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ConfirmModal
                text={t('homework.homeworkNotes.wantToSaveChanges')}
                onClick={() => {
                    dispatch(
                        updateWeeklyDay({
                            classId: params.id,
                            cb: () => {
                                setDataToUpdate(null);
                                navigate(`/weekly-plan/${params.id}`);
                            },
                            data: dataToUpdate,
                        }),
                    );
                }}
                onClose={() => {
                    setDataToUpdate(null);
                }}
                open={dataToUpdate}
            />
        </WhiteBox>
    );
};

export default HomeworkNotes;

/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import './styles.scss';

import ArrowBoldDown from 'src/components/Icons/ArrowBoldDown';
import ArrowBoldUp from 'src/components/Icons/ArrowBoldUp';
import { DataToSave, Labels } from 'src/redux/assessment/types';
import { Student } from '../types';
import { defaultInputValue } from '../defaultInputValue';

const RangeSelect = ({
    dataToSave,
    student,
    currentCategory,
    onSelect,
}: {
    currentCategory: Labels;
    dataToSave: DataToSave[];
    onSelect(currentCategory: Labels, student: Student): (value: string) => void;
    student: Student;
}) => {
    const [inputValue, setInputValue] = useState('0');
    const [maxValue, setMaxValue] = useState(0);
    const [step, setStep] = useState(1);

    const setParentValue = (value: string | number) => {
        if (value === '') {
            onSelect(currentCategory, student)('');
            setInputValue(String(value));
            return;
        }
        if (Number(value) <= maxValue && Number(value) >= 0) {
            let result = value;
            if (Number(value) % 1 !== 0) {
                result = Math.round(Number(value) * 2) / 2;
            }
            setInputValue(String(result));
            const callback = onSelect(currentCategory, student);
            callback(String(result));
        }
    };

    const isPatternScaleHalf = () => {
        return currentCategory.pattern === '0 to X 0.5 scale';
    };

    const getPatternStep = () => {
        return isPatternScaleHalf() ? 0.5 : 1;
    };

    const increment = () => {
        setParentValue(Number(inputValue) + getPatternStep());
    };

    const decrement = () => {
        setParentValue(Number(inputValue) - getPatternStep());
    };

    useEffect(() => {
        if (isPatternScaleHalf()) {
            setStep(getPatternStep());
        }
        setMaxValue(currentCategory.maxValue);
        setInputValue(defaultInputValue(dataToSave, currentCategory, student));
    }, [currentCategory, student]);

    return (
        <div className="range">
            <input
                className="range-input"
                step={step}
                type="number"
                max={maxValue}
                min="0"
                onChange={(event) => setParentValue(event.target.value)}
                value={inputValue}
            />
            <div className="arrow-box">
                <button type="button" tabIndex={-1} className="arrowBold-up" onClick={increment}>
                    <ArrowBoldUp />
                </button>
                <button type="button" tabIndex={-1} className="arrowBold-down" onClick={decrement}>
                    <ArrowBoldDown />
                </button>
            </div>
        </div>
    );
};

export default RangeSelect;

import './styles.scss';
import { TooltipProps } from './types';
import CloseButton from '../CloseButton';

const Tooltip = ({ desc, styles, closeCallback = () => {}, hideButton = false }: TooltipProps) => {
    return (
        <div className="tooltip" style={styles}>
            {desc}
            {!hideButton && (
                <CloseButton
                    onClick={() => {
                        closeCallback();
                    }}
                />
            )}
        </div>
    );
};

export default Tooltip;

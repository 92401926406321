import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import '../styles.scss';
import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import { getStudentTests } from 'src/redux/students/actions';
import { selectStudentTests } from 'src/redux/students/selectors';
import Remove from 'src/components/Icons/Remove';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import SuccessModal from 'src/components/Modals/SuccessModal';
import { selectClasses } from 'src/redux/classes/selectors';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import {
    deleteTestDispatch,
    profileInfoGetReportBlock,
    useAddLeftBorderToTableHeader,
} from '../../helpers';

const ProfileInfoTestsModal = ({
    open,
    onClose,
    profile,
}: {
    open: any;
    onClose: any;
    profile: any;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectStudentTests);
    const { addScroll } = useAddLeftBorderToTableHeader();
    const [openSuccessModal, setOpenSuccessModal] = useState(null as any);
    const params = useParams();
    const [openDeleteModal, setOpenDeleteModal] = useState(null as any);
    const classesData = useSelector(selectClasses);

    useEffect(() => {
        addScroll();
    }, [items]);

    useEffect(() => {
        if (open) {
            dispatch(getStudentTests({ dataType: 'tests', studentId: open }));
        }
    }, [open]);

    if (!open) {
        return <></>;
    }

    return (
        <Modal
            disableOutsideClick={openSuccessModal || openDeleteModal}
            open={open}
            onClose={() => onClose()}
            noPadding
            modalBackdropClass="profileInfoTestModal-backdrop"
        >
            <div className="profileInfoTableModal tests">
                <div className="profileInfoTableModal-title">{t('profile.tests')}</div>
                <Table
                    tableRowsClassName="profileInfoTableModal-table tests"
                    header={
                        <>
                            <span>{t('profile.assignedTests')}</span>
                            <span>{t('profile.score')}</span>
                            <span>{t('emails.date')}</span>
                        </>
                    }
                    rows={items.map((oneResult: any) => {
                        return (
                            <>
                                <span title={getNoEmpty(oneResult.name)}>
                                    {getNoEmpty(oneResult.name)}
                                </span>
                                <span title={getNoEmpty(oneResult.score)}>
                                    {oneResult.score ? `${getNoEmpty(oneResult.score)}%` : ''}
                                </span>
                                <span className="profileInfoTestsModal-buttons">
                                    <div className="profileInfoTestsModal-buttons-time">
                                        {moment.unix(oneResult.timestamp).format()}
                                    </div>
                                    {profileInfoGetReportBlock(
                                        oneResult,
                                        profile,
                                        open,
                                        classesData,
                                    )}
                                    <div
                                        onClick={() => {
                                            setOpenDeleteModal(oneResult);
                                        }}
                                        className="sc_hover_color_text_svg cursor-pointer"
                                    >
                                        <Remove />
                                    </div>
                                </span>{' '}
                            </>
                        );
                    })}
                />
                <ConfirmModal
                    open={openDeleteModal}
                    text={t('profile.confirmTestDeletion')}
                    onClick={() => {
                        deleteTestDispatch(
                            openDeleteModal,
                            params,
                            setOpenSuccessModal,
                            dispatch,
                            t,
                        );
                    }}
                    onClose={() => setOpenDeleteModal(null)}
                />
                <SuccessModal
                    lottie
                    open={openSuccessModal}
                    text={openSuccessModal}
                    onClose={() => {
                        setOpenSuccessModal(false);
                        onClose();
                    }}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoTestsModal;

import { useNavigate, useParams } from 'react-router';
import ELearningDescription from 'src/components/ELearningDescription';
import Button from 'src/components/Button';
import CheckedType2 from 'src/components/Icons/Checked-type-2';
import { ELearningType } from 'src/redux/weeklyPlan/types';
import { useTranslation } from 'react-i18next';

const NewAssignmentsELearningActivities = ({
    eLearning,
    num,
    state,
}: {
    eLearning: ELearningType[];
    num: number;
    state: any;
}) => {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isCurrentAssignmentAdded = (assignment?: { id: number }) => {
        return state?.updateLesson?.activities
            ?.map(({ id }: { id: number }) => id)
            .includes(assignment?.id);
    };

    return (
        <div className="NewAssignmentsEL-activities">
            <ELearningDescription eLearning={eLearning} num={num} />
            {eLearning.length ? (
                <div className="elearning-footer">
                    {isCurrentAssignmentAdded(eLearning[num]) ? (
                        <Button onClick={() => {}} type="secondary">
                            <>
                                <CheckedType2 />
                                {`${t('NewAssigment.added')} `}
                            </>
                        </Button>
                    ) : (
                        <Button
                            type="secondary"
                            onClick={() => {
                                let addToResourcesResult = {};
                                if (state) {
                                    if ('addToResources' in state && state.addToResources.length) {
                                        const tempAddToResources = structuredClone(
                                            state.addToResources,
                                        );
                                        tempAddToResources.push(eLearning[num]);
                                        addToResourcesResult = {
                                            addToResources: tempAddToResources,
                                        };
                                    } else {
                                        addToResourcesResult = {
                                            addToResources: [eLearning[num]],
                                        };
                                    }
                                }
                                navigate(
                                    `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}`,
                                    {
                                        state: Object.assign(state ?? {}, addToResourcesResult),
                                    },
                                );
                            }}
                        >
                            {t('NewAssigment.addToHomework')}
                        </Button>
                    )}
                </div>
            ) : null}
        </div>
    );
};
export default NewAssignmentsELearningActivities;

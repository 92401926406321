import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { selectLoading } from 'src/redux/loading/selectors';
import noData1 from 'src/assets/images/noData1.gif';
import { Props } from './types';

const TableLight = ({ header, rows, customEmptyMessage, tableRowsClassName }: Props) => {
    const { t } = useTranslation();
    const loading = useSelector(selectLoading);
    if (!rows.length && !loading) {
        return (
            <>
                {!customEmptyMessage ? (
                    <div className="tableLight-noData">
                        <img src={noData1} alt="noData" />
                        <span>{t('profile.noData')}</span>
                    </div>
                ) : (
                    <div className="tableLight-noData custom-empty-message">
                        <span>{customEmptyMessage}</span>
                    </div>
                )}
            </>
        );
    }

    return (
        <div>
            <div className="tableLight-header">{header}</div>
            <hr />
            <div className={classNames('tableLight-header-rows', tableRowsClassName)}>
                {rows.map((row, i) => (
                    <div className="tableLight-row" key={i}>
                        {row}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TableLight;

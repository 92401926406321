import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import InviteStudentButtonModal from 'src/components/InviteStudentButtonModal';
import Search from 'src/components/Search';
import HeaderButton from 'src/components/HeaderButton';
import SelectMenu from 'src/components/SelectMenu';
import SettingsIcon from 'src/components/Icons/Settings';
import AllStudentsFiltersModal from 'src/components/Modals/AllUsersFiltersModal';
import { getAllStudents } from 'src/redux/allStudents/actions';
import DuplicateIcon from 'src/components/Icons/DuplicateIcon';
import { selectDuplicatedStudents } from 'src/redux/users/selectors';
import { getDuplicatedStudents } from 'src/redux/users/actions';
import { selectAllStudents } from 'src/redux/allStudents/selectors';
import { useFilterHelper } from 'src/hooks/useFilterHelper';
import UnmatchedStudentsIcon from 'src/components/Icons/UnmatchedStudentsIcon';
import { urlQueryFromObject } from 'src/helpers/queryFromObject';
import { appConstants, PAGINATION_LIMITS } from 'src/constants';
import TablePaginationAPI from 'src/components/TablePaginationAPI';
import { usePaginationAPIProcessor } from 'src/hooks/usePaginationProcessorAPI';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import Button from 'src/components/Button';
import CalendarX from 'src/components/Icons/CalendarX';
import FileLock from 'src/components/Icons/FileLock';
import Modal from 'src/components/Modal';
import InputCalendar from 'src/components/InputCalendar';
import { bulkUpdateStudents } from 'src/redux/students/actions';
import { selectLayout } from 'src/redux/layout/selectors';
import './styles.scss';
import { useTranslation } from 'react-i18next';

type StudentHeaderProps = {
    filter: any;
    setFilter: any;
    showActionButtons?: boolean;
    selectedStudents?: number[];
    setSelectedStudents?: (arr: number[]) => void;
};

const ActionPopup = ({
    close,
    action,
    selectedStudents,
    filter,
}: {
    close: () => void;
    action: null | 'end' | 'block';
    selectedStudents: number[];
    filter: any;
}) => {
    const allStudents = useSelector(selectAllStudents);
    const defaultEndDate = allStudents.data.find(
        (student) => student.uid === selectedStudents[0],
    )?.end;
    const defaultAccessDate = allStudents.data.find(
        (student) => student.uid === selectedStudents[0],
    )?.accessed;
    const [courseEndDate, setCourseEndDate] = useState(defaultEndDate);
    const [accessEndDate, setAccessEndDate] = useState(defaultAccessDate);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        setCourseEndDate(defaultEndDate);
        setAccessEndDate(defaultAccessDate);
    }, [defaultEndDate, defaultAccessDate]);

    if (!action) {
        return null;
    }

    const handleConfirm = () => {
        const updatedStudents: {
            uid: number;
            endDate?: number;
            endAccess?: number;
            status?: boolean;
        }[] = [];

        if (action === 'end') {
            selectedStudents.forEach((uid) => {
                updatedStudents.push({
                    uid,
                    endDate: courseEndDate,
                    endAccess: accessEndDate,
                });
            });
        } else {
            selectedStudents.forEach((uid) => {
                updatedStudents.push({
                    uid,
                    status: false,
                });
            });
        }

        dispatch(
            bulkUpdateStudents({
                updatedStudents,
                cb: () => {
                    close();
                    dispatch(getAllStudents(filter));
                },
            }),
        );
    };

    return (
        <Modal open={!!action} onClose={close} noPadding>
            <div className="qrcodes-popup">
                <div className="qrcodes-popup-head">
                    {action === 'end'
                        ? t('allStudents.endStudentCourse')
                        : t('general.blockAccess')}
                </div>
                <div className="qrcodes-popup-content">
                    {action === 'end' ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                maxWidth: '15rem',
                                margin: '0 auto',
                            }}
                        >
                            <div>
                                {t('profile.courseEndDate')}
                                <InputCalendar
                                    isUtc
                                    onChange={(e: any) => {
                                        setCourseEndDate(e / 1000);
                                    }}
                                    date={new Date((courseEndDate || 0) * 1000)}
                                />
                            </div>
                            <div>
                                {t('profile.accessEndDate')}
                                <InputCalendar
                                    isUtc
                                    onChange={(e: any) => {
                                        setAccessEndDate(e / 1000);
                                    }}
                                    date={new Date((accessEndDate || 0) * 1000)}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            {t('general.accessWillBeBlocked')}{' '}
                            <b>
                                for {selectedStudents.length} {t('general.students').toLowerCase()}
                            </b>
                            .
                            <br />
                            {t('general.accessEndDate')}
                            {t('general.pleaseConfirm')}
                        </>
                    )}
                </div>
                <div className="qrcodes-popup-footer">
                    <Button type="outline-grey" onClick={() => close()} minWidth={80}>
                        {t('general.cancel')}
                    </Button>
                    <Button maxWidth={80} type="secondary" onClick={() => handleConfirm()}>
                        {t('general.OK')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const StudentsHeader = ({
    filter,
    setFilter,
    showActionButtons,
    selectedStudents,
    setSelectedStudents,
}: StudentHeaderProps) => {
    const { isModuleActive, getModuleName } = useIsModuleActive();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentLimit, setCurrentLimit] = useState(PAGINATION_LIMITS[0]);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const { updateFilter, checkFilter } = useFilterHelper({ filter, setFilter });
    const duplicatedStudents = useSelector(selectDuplicatedStudents);
    const allStudents = useSelector(selectAllStudents);
    const layout = useSelector(selectLayout);
    const paginationProcessor = usePaginationAPIProcessor({
        items: allStudents,
        limit: allStudents.recordsPerPage,
    });
    const [action, setAction] = useState<null | 'end' | 'block'>(null);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getDuplicatedStudents({}));
    }, []);

    const duplicatedStudentsLength =
        duplicatedStudents.length > 9 ? '9+' : duplicatedStudents.length;
    return (
        <>
            <div className="all-students-header">
                <div className="all-students-header__search-block">
                    <Search
                        onPushEnter={() => {
                            dispatch(getAllStudents(filter));
                        }}
                        alwaysWide
                        onChange={(text: string) => {
                            updateFilter({ search: text });
                        }}
                    />
                    <div className="all-students-header__filters-button">
                        {isModuleActive(Modules.StudentsUnmatched) && (
                            <HeaderButton to="/all-students/unmatched-students">
                                <UnmatchedStudentsIcon />
                                <span className="all-students-header__invite-student-title">
                                    {getModuleName(Modules.StudentsUnmatched) ||
                                        t('allStudents.unmatchedInvites')}
                                </span>
                            </HeaderButton>
                        )}
                        <HeaderButton onClick={() => setOpenFilterModal(true)}>
                            <SettingsIcon />
                            <span>{t('general.filters')}</span>
                        </HeaderButton>
                    </div>
                </div>

                <div className="all-students-header__buttons">
                    {isModuleActive(Modules.StudentsUnmatched) && (
                        <HeaderButton to="/all-students/unmatched-students">
                            <UnmatchedStudentsIcon />
                            <span className="all-students-header__invite-student-title">
                                {getModuleName(Modules.StudentsUnmatched) ||
                                    t('allStudents.unmatchedInvites')}
                            </span>
                        </HeaderButton>
                    )}

                    {isModuleActive(Modules.StudentsDuplicate) && (
                        <HeaderButton
                            infoText={duplicatedStudentsLength}
                            to="/all-students/duplicate-students"
                        >
                            <DuplicateIcon />
                            <span className="all-students-header__invite-student-title">
                                {getModuleName(Modules.StudentsDuplicate) ||
                                    t('general.duplicates')}
                            </span>
                        </HeaderButton>
                    )}
                    <InviteStudentButtonModal
                        page="studentFilter"
                        withPreSessionalClass={layout.layout.school['pre-sessional']}
                    />
                    <HeaderButton
                        to={`${appConstants.api}/staff/students?${urlQueryFromObject(
                            filter,
                        )}&csv=true`}
                    >
                        {t('allStudents.CSVDownload')}
                    </HeaderButton>
                    <div className="all-students-header__limit">
                        <span className="all-students-header__limit-title">
                            {t('general.show')}
                        </span>
                        <SelectMenu
                            wrapperClass="type_2"
                            options={PAGINATION_LIMITS.map((limit) => {
                                return {
                                    content: (
                                        <div title={limit.toString()}>
                                            <span>{limit}</span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        setCurrentLimit(limit);
                                        updateFilter({ recordsPerPage: limit });
                                    },
                                };
                            })}
                            text={currentLimit}
                        />
                    </div>
                    <TablePaginationAPI
                        mob={false}
                        paginationProcessor={paginationProcessor}
                        updatePage={({ page }) => {
                            updateFilter({ page });
                            dispatch(getAllStudents(filter));
                            if (setSelectedStudents) {
                                setSelectedStudents([]);
                            }
                        }}
                    />
                </div>
            </div>
            {showActionButtons && (
                <div style={{ display: 'flex', gap: '1rem', padding: '0 1rem 1rem' }}>
                    <Button type="outline-grey" onClick={() => setAction('end')} padding="0.75rem">
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <CalendarX />
                            {t('general.endCourse')}
                        </div>
                    </Button>
                    <Button
                        type="square"
                        onClick={() => setAction('block')}
                        padding="0.75rem"
                        className="svg-stroke-hover-color-white"
                    >
                        <div
                            className="profile-main__buttons-button-content"
                            style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                        >
                            <FileLock />
                            {t('general.blockAccess')}
                        </div>
                    </Button>
                </div>
            )}
            <AllStudentsFiltersModal
                open={openFilterModal}
                setOpenFilterModal={setOpenFilterModal}
                filter={filter}
                setFilter={setFilter}
                onClose={() => {
                    setOpenFilterModal(false);
                }}
            />
            <ActionPopup
                action={action}
                close={() => setAction(null)}
                selectedStudents={selectedStudents || []}
                filter={filter}
            />
        </>
    );
};
export default StudentsHeader;

export const defaultInputValue = (dataToSave: any, currentCategory: any, student: any) => {
    return (
        dataToSave.find(
            (data: any) => data.label === currentCategory.id && data.uid === student.uid,
        )?.value ??
        student.labels.find(({ id }: any) => id === currentCategory.id)?.value ??
        null ??
        ''
    );
};

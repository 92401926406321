import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import './styles.scss';
import Swiper from 'react-id-swiper';
import WhiteBox from 'src/components/WhiteBox';
import { selectUnits, selectWeeklyPlan } from 'src/redux/weeklyPlan/selectors';
import Add from 'src/components/Icons/Add';
import { getElearning, updateWeeklyDay } from 'src/redux/weeklyPlan/actions';
import { useWindowSize } from 'src/hooks/useWindowSize';
import Disc from 'src/components/Icons/Disc';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { useCallbackPrompt } from 'src/hooks/useCallbackPrompt';
import { useTranslation } from 'react-i18next';
import HomeworkNotes from '../HomeworkNotes';
import MyAssignmentsMobile from '../MyAssigmentsMobile';
import ELearning from '../ELearning';
import { homeworkAddResourcesFromState } from '../helpers';

const MobileHomework = () => {
    const params = useParams();
    const { t } = useTranslation();
    const { lessons, days } = useSelector(selectWeeklyPlan) as any;
    const navigate = useNavigate();
    const state = useLocation().state as any;
    const units = useSelector(selectUnits);
    const [tab, setTab] = useState('assignments');
    const [activeEx, setActiveEx] = useState({} as any);
    const dispatch = useDispatch();
    const [step, setStep] = useState(2);
    const [unit, setUnit] = useState('');
    const weekly = useSelector(selectWeeklyPlan) as any;
    const [dataToUpdate, setDataToUpdate] = useState(null as any);
    const [swiperZIndex, setSwiperZIndex] = useState(null as any);

    const [isDirty, setIsDirty] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty) as any;
    const [showPromptModal, setShowPromptModal] = useState(false as any);

    const { columnId, lessonId } =
        lessons
            ?.find(({ day }: any) => day === +params.day!)
            ?.lessons.find(({ name }: any) => /homework/i.test(name.trim())) || {};
    const currentLesson = days
        ?.find(({ day }: any) => day === +params.day!)
        ?.lessons.find(
            ({ columnId: colId, lessonId: lesId }: any) => columnId === colId && lessonId === lesId,
        );

    const [updateLesson, setUpdateLesson] = useState(currentLesson);

    useEffect(() => {
        if (state && state.tab) {
            setTab(state.tab);
        }
    }, [state]);

    useEffect(() => {
        if (activeEx.id) {
            dispatch(
                getElearning({
                    classId: params.id,
                    id: activeEx.id,
                    cb: () => setStep(2),
                }),
            );
        }
    }, [activeEx, params]);

    const allSyllabus = useMemo(() => {
        const currentDayLessons = days?.find(({ day }: any) => day === +params.day!)?.lessons;
        let temp: any = [];
        temp = currentDayLessons?.map((val: any, key: any) => {
            return val?.syllabus.map(({ id }: any) => id);
        });
        return temp?.flat();
    }, [currentLesson]);

    const currentUnits = useMemo(() => {
        return units
            .map(({ name, pages }: any) => {
                return {
                    name,
                    pages: pages.filter(({ id }: any) => allSyllabus?.includes(id)),
                };
            })
            .filter(({ pages }: any) => pages.length);
    }, [units, allSyllabus]);

    const { width } = useWindowSize();
    const paramsSlider =
        width <= 991
            ? {
                  slidesPerView: 1.1,
                  spaceBetween: 20,
              }
            : {
                  slidesPerView: 2.1,
                  spaceBetween: 20,
              };

    useEffect(() => {
        if (currentUnits[0]) {
            setStep(1);
            setTab('learning');
            if (state && state.activeEx) {
                setActiveEx(state.activeEx);
            }
        }
    }, [currentUnits]);

    useEffect(() => {
        setShowPromptModal(showPrompt);
    }, [showPrompt]);

    useEffect(() => {
        if (state && state.updateLesson) {
            setIsDirty(true);
            setUpdateLesson(state.updateLesson);
        } else {
            setUpdateLesson(currentLesson);
        }
        homeworkAddResourcesFromState({ state, currentLesson, setIsDirty, setUpdateLesson });
    }, [currentLesson, state]);

    return (
        <div className="homework-mobile">
            {step === 1 ? (
                <div className="homework-step1">
                    <div className="homework-header">
                        <span className="homework-title">{t('homework.mobile.step1')}</span>
                        <span className="homework-subtitle">
                            {t('homework.mobile.itemOptionsSelection')}
                        </span>
                    </div>
                    <WhiteBox>
                        <div className="homework-step1-content">
                            {currentUnits.map(({ name, pages }: any) => (
                                <div key={name} className="homework-unit">
                                    <span className="homework-unit-name">
                                        {`${t('homework.mobile.unit')} ${name}`}
                                    </span>
                                    {pages.map((ex: any) => (
                                        <span
                                            key={ex.id}
                                            className={classNames(
                                                'homework-unit-exercise sc_hover_color_text_svg',
                                                {
                                                    'homework-unit-exercise--active':
                                                        ex.id === activeEx.id,
                                                },
                                            )}
                                            onClick={() => {
                                                setActiveEx(ex);
                                                setUnit(name);
                                            }}
                                        >
                                            {ex.exName}
                                        </span>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </WhiteBox>
                </div>
            ) : (
                <>
                    <div
                        onClick={() => {
                            setStep(1);
                            setActiveEx({});
                        }}
                        className="homework-back pr_hover_color_text_svg_bg cursor-pointer"
                    >
                        <span>{'<'}</span>
                        <b>
                            {t('homework.mobile.unit')} {unit}
                        </b>
                        <span>{activeEx.exName}</span>
                    </div>
                    <Swiper {...paramsSlider} wrapperClass="homework-swiper">
                        <div
                            className="homework-step2"
                            style={{ zIndex: swiperZIndex === 2 ? 1 : 0 }}
                            onMouseEnter={() => setSwiperZIndex(2)}
                            onPointerEnter={() => setSwiperZIndex(2)}
                        >
                            <div className="homework-header">
                                <span className="homework-title">{t('homework.mobile.step2')}</span>
                                <span className="homework-subtitle">
                                    {t('homework.mobile.chooseOptionOrAddOwn')}
                                </span>
                            </div>
                            <WhiteBox>
                                <div className="homework-tabBar">
                                    <div className="homework-tabs">
                                        <span
                                            className={classNames(
                                                'homework-tab sc_hover_color_text_svg',
                                                {
                                                    'homework-tab--active': tab === 'learning',
                                                },
                                            )}
                                            onClick={() => setTab('learning')}
                                        >
                                            {t('homework.mobile.eLearning')}
                                        </span>
                                        <span
                                            className={classNames(
                                                'homework-tab sc_hover_color_text_svg',
                                                {
                                                    'homework-tab--active': tab !== 'learning',
                                                },
                                            )}
                                            onClick={() => setTab('assignments')}
                                        >
                                            {t('homework.mobile.myAssignments')}
                                        </span>
                                    </div>

                                    <div
                                        className="homework-create pr_hover_color_text_svg_bg"
                                        onClick={() => {
                                            navigate(
                                                `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}/assigment`,
                                                {
                                                    state: {
                                                        activeEx,
                                                        addToResources:
                                                            state && 'addToResources' in state
                                                                ? state.addToResources
                                                                : [],
                                                        updateLesson,
                                                    },
                                                },
                                            );
                                            confirmNavigation();
                                        }}
                                    >
                                        <Add />
                                    </div>
                                </div>

                                {tab === 'learning' ? (
                                    <ELearning
                                        setIsDirty={setIsDirty}
                                        setUpdateLesson={(data) => setUpdateLesson(data)}
                                        activeEx={activeEx}
                                        updateLesson={updateLesson}
                                    />
                                ) : (
                                    <MyAssignmentsMobile
                                        setUpdateLesson={(data) => setUpdateLesson(data)}
                                        updateLesson={updateLesson}
                                    />
                                )}
                            </WhiteBox>
                        </div>
                        <div
                            className="homework-step3"
                            style={{ zIndex: swiperZIndex === 3 ? 1 : 0 }}
                            onMouseEnter={() => setSwiperZIndex(3)}
                            onPointerEnter={() => setSwiperZIndex(3)}
                        >
                            <div className="homework-step3-head">
                                <div className="homework-header">
                                    <span className="homework-title">
                                        {t('homework.mobile.step3')}
                                    </span>
                                    <span className="homework-subtitle">
                                        {t('homework.mobile.addNotesAndSave')}
                                    </span>
                                </div>
                                <div
                                    className="homeworkNotes-disc homeworkNotes-disc-mobile pr_hover_color_text_svg_bg"
                                    onClick={() => {
                                        const toUpdate = JSON.parse(JSON.stringify(weekly));
                                        const dayIndex = toUpdate.days.findIndex(
                                            ({ day }: any) => day === +params.day!,
                                        );
                                        const lessonToUpdate = weekly.days[
                                            dayIndex
                                        ].lessons.findIndex(
                                            ({ lessonId: lesId, columnId: colId }: any) =>
                                                lesId === updateLesson.lessonId &&
                                                colId === updateLesson.columnId,
                                        );
                                        toUpdate.days[dayIndex].lessons[lessonToUpdate] =
                                            updateLesson;
                                        setDataToUpdate(toUpdate);
                                    }}
                                >
                                    <Disc />
                                    {t('homework.mobile.save')}
                                </div>
                            </div>

                            <HomeworkNotes
                                setIsDirty={setIsDirty}
                                currentLesson={updateLesson}
                                currentUnits={currentUnits}
                                setUpdateLesson={(data) => setUpdateLesson(data)}
                            />
                        </div>
                    </Swiper>
                </>
            )}
            <ConfirmModal
                text={t('homework.mobile.wantToSaveChanges')}
                onClick={() => {
                    setIsDirty(false);
                    dispatch(
                        updateWeeklyDay({
                            classId: params.id,
                            cb: () => {
                                setDataToUpdate(null);
                                navigate(`/weekly-plan/${params.id}`);
                            },
                            data: dataToUpdate,
                        }),
                    );
                }}
                onClose={() => {
                    setDataToUpdate(null);
                }}
                open={dataToUpdate}
            />
            <ConfirmModal
                text={t('homework.mobile.unsavedChangesConfirmation')}
                onClick={() => {
                    confirmNavigation();
                }}
                onClose={() => {
                    setShowPromptModal(false);
                    cancelNavigation();
                }}
                open={showPromptModal}
            />
        </div>
    );
};

export default MobileHomework;

import classNames from 'classnames';
import { useCallback } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Add from 'src/components/Icons/Add';
import Pencil from 'src/components/Icons/Pencil';
import CustomLink from 'src/components/CustomLink';
import Tooltip from 'src/components/Tooltip';
import { selectWeeklyPlanCuratedHeads } from 'src/redux/weeklyPlan/selectors';
import { useTranslation } from 'react-i18next';
import { add } from '../helpers';
import CustomDraggable from '../CustomDraggable';
import { EditableColumnType } from '../types';
import '../styles.scss';

const CustomDroppable = ({
    lessons,
    columnIndex,
    lessonSubName,
    lessonDays,
    lessonIndex,
    column,
    columnIds,
    lessonIds,
    editedColumns,
    setEditedColumns,
    columns,
    setColumns,
    isHomework,
    week,
    year,
    setTooltips,
}: {
    [key: string]: any;
    column: EditableColumnType[];
    columns: EditableColumnType[][];
}) => {
    const { t } = useTranslation();
    const handleDebounceFn = (text: string) => setEditedColumns(text);
    const debounceFn = useCallback(debounce(handleDebounceFn, 100), []);
    const navigate = useNavigate();
    const params = useParams();
    const curatedHeads = useSelector(selectWeeklyPlanCuratedHeads);

    const droppableId = `${columnIndex}-${lessonSubName}-${lessonDays[columnIndex]?.columnId}-${lessonDays[columnIndex]?.lessonId}-${lessonIndex}`;
    return (
        <div
            style={{
                width: `calc(100% / ${lessons.length})`,
            }}
        >
            <Droppable droppableId={droppableId} key={columnIndex}>
                {(providedDroppable) => {
                    return (
                        <div
                            {...providedDroppable.droppableProps}
                            ref={providedDroppable.innerRef}
                            className="weeklyPlan-droppable"
                        >
                            <div className="weeklyPlan-add">
                                {isHomework && (
                                    <CustomLink
                                        to={`/weekly-plan/${params.id}/${year}/${week}/${lessons[columnIndex].day}`}
                                        className="weeklyPlan-pencil weeklyPlan-add__svg-parent sc_hover_color_text_svg"
                                    >
                                        <Tooltip
                                            styles={{
                                                left: columnIndex === 4 ? '-223px' : '-120px',
                                                minWidth: '258px',
                                            }}
                                            desc={t('weeklyPlan.addHomework')}
                                            closeCallback={() =>
                                                setTooltips({
                                                    editHomework: false,
                                                })
                                            }
                                        />
                                        <Pencil />
                                    </CustomLink>
                                )}
                                <div
                                    className={classNames(
                                        'weeklyPlan-add__svg-parent sc_hover_color_text_svg',
                                        {
                                            'd-none': curatedHeads.includes(lessonSubName),
                                        },
                                    )}
                                    onClick={() => {
                                        add(
                                            columns,
                                            setColumns,
                                            columnIndex,
                                            {
                                                ...lessonDays[columnIndex],
                                            },
                                            lessonSubName,
                                        );
                                    }}
                                >
                                    <Tooltip
                                        desc={t('weeklyPlan.addNewBlock')}
                                        closeCallback={() =>
                                            setTooltips({
                                                addNewBlock: false,
                                            })
                                        }
                                    />
                                    <Add fill="#bcbcbc" />
                                </div>
                            </div>

                            {column
                                .filter((item) => {
                                    if (!lessonSubName) {
                                        return true;
                                    }
                                    return item.head === lessonSubName;
                                })
                                .map((item, indexDraggable: number) => {
                                    if (
                                        !columnIds.includes(item.columnId) ||
                                        !lessonIds.includes(item.lessonId)
                                    ) {
                                        return null;
                                    }
                                    const isCuratedHead = curatedHeads.includes(item.head);

                                    return (
                                        <CustomDraggable
                                            key={item.id}
                                            item={item}
                                            indexDraggable={indexDraggable}
                                            columnIndex={columnIndex}
                                            editedColumns={editedColumns}
                                            setColumns={setColumns}
                                            columns={columns}
                                            setEditedColumns={setEditedColumns}
                                            editContent={debounceFn}
                                            isCuratedHead={isCuratedHead}
                                        />
                                    );
                                })}
                            {providedDroppable.placeholder}
                        </div>
                    );
                }}
            </Droppable>
        </div>
    );
};

export default CustomDroppable;

import { WeekDayType } from 'src/redux/weeklyPlan/types';
import { v4 as uuidv4 } from 'uuid';

export const WEEKLY_PLAN_ITEM_EDIT_ON = 1;
export const WEEKLY_PLAN_ITEM_EDIT_OFF = 2;

export const WEEKLY_PLAN_ITEM_CURATED_ON = 1;
export const WEEKLY_PLAN_ITEM_CURATED_OFF = 2;

const getEditableStatus = (content: string) => {
    return /data-editable=.false./i.test(content)
        ? WEEKLY_PLAN_ITEM_EDIT_OFF
        : WEEKLY_PLAN_ITEM_EDIT_ON;
};

const getCuratedStatus = (content: string) => {
    return /data-curated=.true./i.test(content)
        ? WEEKLY_PLAN_ITEM_CURATED_ON
        : WEEKLY_PLAN_ITEM_CURATED_OFF;
};

let lessonsSubNamesCurated: string[] = [];
const pushToCurated = (content: string, head: string) => {
    if (getCuratedStatus(content) === WEEKLY_PLAN_ITEM_CURATED_ON) {
        lessonsSubNamesCurated = [...lessonsSubNamesCurated, head];
    }
};

const getTeacherCol = (
    lessonSubNames: Record<string, any>,
    lessonIndex: number,
    lessonId: number,
    columnId: number,
    day: WeekDayType,
    teacherText: string,
) => {
    const teacherName = lessonIndex ? 'Teacher Note##' : 'Suggested extensions and teacher notes##';
    const head = `${teacherName}${lessonIndex}`;
    if (!lessonSubNames[head]) {
        lessonSubNames[head] = {
            lessonIds: [lessonId],
            columnIds: [columnId],
            index: 999999,
        };
    } else {
        lessonSubNames[head].lessonIds.push(lessonId);
        lessonSubNames[head].columnIds.push(columnId);
    }

    const results: any[] = [];
    if (teacherText) {
        const regGetBlocks = '<(?:p|div).*?>(.*?)</(?:div|p)>';
        const regGetNotClosedBlocks = '(.*?)</p>';
        const matchResults = teacherText.matchAll(
            new RegExp(`${regGetBlocks}|${regGetNotClosedBlocks}`, 'gi'),
        );
        const regExpMatchArrays = Array.from(matchResults);
        regExpMatchArrays.forEach((element) => {
            const editable = getEditableStatus(element[0]);
            const curated = getCuratedStatus(element[0]);
            const id = uuidv4();
            if (element[1] === undefined) {
                results.push({
                    id,
                    content: element[2].replace(`@empty text@`, ''),
                    columnId,
                    lessonId,
                    type: 'teacher',
                    day: day.day,
                    head,
                    editable,
                    curated,
                });
            } else {
                results.push({
                    id,
                    content: element[1].replace(`@empty text@`, ''),
                    columnId,
                    lessonId,
                    type: 'teacher',
                    day: day.day,
                    head,
                    editable,
                    curated,
                });
            }
        });
    } else {
        results.push({
            id: uuidv4(),
            content: teacherText.replace(`@empty text@`, ''),
            columnId,
            lessonId,
            type: 'teacher',
            day: day.day,
            head,
            editable: WEEKLY_PLAN_ITEM_EDIT_ON,
            curated: WEEKLY_PLAN_ITEM_CURATED_OFF,
        });
    }
    pushToCurated(teacherText, head);
    return results;
};

const getStudentCol = (
    lessonSubNames: Record<string, any>,
    lessonIndex: number,
    lessonId: number,
    columnId: number,
    day: WeekDayType,
    studentText: string,
) => {
    if (studentText === '') {
        lessonSubNames[`##${lessonIndex}`] = {
            lessonIds: [lessonId],
            columnIds: [columnId],
        };
        const head = `##${lessonIndex}`;
        pushToCurated(studentText, head);
        return [
            {
                id: uuidv4(),
                content: '',
                columnId,
                lessonId,
                type: 'student',
                day: day.day,
                head,
            },
        ];
    }

    const studentData = [] as any;
    const a = studentText.match(/<\/?(?:strong)[^>]*>\s*/im)
        ? studentText
        : `<strong></strong>${studentText}`;

    const b = a.split(/<\/?(?:strong)[^>]*>\s*/im);
    const c = b.filter((text: string) => {
        if (text.match(/<\/?(?:div|p)[^>]*>\s*/i)) {
            return text.replace(/<\/?(?:div|p)[^>]*>\s*/im, '');
        }
        return true;
    });

    c.reduce((prev: string, next: string, index: number) => {
        if (!next.match(/<\/?(?:div|p)[^>]*>\s*/im)) {
            if (lessonSubNames[`${next}##${lessonIndex}`]) {
                lessonSubNames[`${next}##${lessonIndex}`].lessonIds.push(lessonId);
                lessonSubNames[`${next}##${lessonIndex}`].columnIds.push(columnId);

                if (lessonSubNames[`${next}##${lessonIndex}`].index !== index && !day.day) {
                    lessonSubNames[`${next}##${lessonIndex}`].index = index;
                }
            } else {
                lessonSubNames[`${next}##${lessonIndex}`] = {
                    lessonIds: [lessonId],
                    columnIds: [columnId],
                    index,
                };
            }

            return next;
        }
        const result: any[] = [];
        const head = `${prev}##${lessonIndex}` || `##${lessonIndex}`;
        pushToCurated(studentText, head);
        if (next) {
            const matchResult = next.matchAll(/<(?:p|div).*?>(.*?)<\/(?:div|p)>/gi);
            Array.from(matchResult).forEach((element) => {
                result.push({
                    id: uuidv4(),
                    content: element[1].replace(`@empty text@`, ''),
                    columnId,
                    lessonId,
                    type: 'student',
                    head,
                    day: day.day,
                    editable: getEditableStatus(element[0]),
                    curated: getCuratedStatus(element[0]),
                });
            });
        } else {
            result.push({
                id: uuidv4(),
                content: '',
                columnId,
                lessonId,
                type: 'student',
                head,
                day: day.day,
                editable: WEEKLY_PLAN_ITEM_EDIT_ON,
                curated: WEEKLY_PLAN_ITEM_CURATED_OFF,
            });
        }
        studentData.push(result);
        return '';
    }, '');

    return studentData;
};

export const prepareColumns = (days: WeekDayType[]) => {
    lessonsSubNamesCurated = [];
    const lessonSubNames: Record<string, any> = {};
    const copiedDays = JSON.parse(JSON.stringify(days)) as typeof days;
    copiedDays.sort((prev, next) => {
        const prevStudentTextCount = prev.lessons[0].studentText.split('<p><strong>').length;
        const nextStudentTextCount = next.lessons[0].studentText.split('<p><strong>').length;

        if (prevStudentTextCount < nextStudentTextCount) {
            return 1;
        }
        if (prevStudentTextCount > nextStudentTextCount) {
            return -1;
        }
        return 0;
    });

    const columns = copiedDays.map((day) => {
        return {
            day: day.day,
            data: day.lessons
                .map((lesson, lessonIndex: number) => {
                    const { studentText, teacherText, columnId, lessonId } = lesson;

                    /*mutable objects lessonSubNames*/
                    const studentCol = getStudentCol(
                        lessonSubNames,
                        lessonIndex,
                        lessonId,
                        columnId,
                        day,
                        studentText,
                    ).flat();

                    const teacherCol = getTeacherCol(
                        lessonSubNames,
                        lessonIndex,
                        lessonId,
                        columnId,
                        day,
                        teacherText,
                    );

                    if (!studentCol.length && !teacherCol.length) {
                        return [];
                    }

                    return [...teacherCol, ...studentCol];
                })
                .flat(),
        };
    });

    const ordered = Object.keys(lessonSubNames)
        .sort((prev, next) => {
            return lessonSubNames[prev].index - lessonSubNames[next].index;
        })
        .reduce((obj: Record<string, any>, key: string) => {
            obj[key] = lessonSubNames[key];
            return obj;
        }, {});

    return {
        columns: (JSON.parse(JSON.stringify(columns)) as typeof columns)
            .sort((prev, next) => {
                if (prev.day < next.day) {
                    return -1;
                }
                if (prev.day > next.day) {
                    return 1;
                }
                return 0;
            })
            .map((day) => {
                return day.data;
            }),
        lessonSubNames: ordered,
        curatedLessonSubNames: Array.from(new Set(lessonsSubNamesCurated)),
    };
};

export const add = (
    columns: any,
    setColumns: any,
    dayIndex: number,
    { columnId, lessonId }: { columnId: number; lessonId: number },
    lessonSubName: Record<string, any>,
) => {
    const newColumns = JSON.parse(JSON.stringify(columns));
    const teacherTypes = ['Suggested extensions and teacher notes', 'Teacher Note'];
    const indexToPaste = newColumns[dayIndex].findIndex((column: any) => {
        return column.head === lessonSubName;
    });

    newColumns[dayIndex].splice(indexToPaste, 0, {
        id: uuidv4(),
        content: '',
        columnId,
        lessonId,
        day: dayIndex + 1,
        type: teacherTypes.some((element) => lessonSubName.includes(element))
            ? 'teacher'
            : 'student',
        head: lessonSubName,
        isNewItem: true,
    });

    setColumns(newColumns);
};

export const removeColumn = (columns: any, setColumns: any, columnIndex: any, itemId: any) => {
    const newColumns = JSON.parse(JSON.stringify(columns));
    const indexDraggable = newColumns[columnIndex].findIndex(({ id }: any) => {
        return id === itemId;
    });
    newColumns[columnIndex].splice(indexDraggable, 1);
    setColumns(newColumns);
};

export const getDaysAndColumnsAfterDelete = ({
    columns,
    index,
    getUpdatedDays,
    lesson,
}: {
    columns: [];
    index: number;
    getUpdatedDays: any;
    lesson: any;
}): {
    days: any[];
    columns: [];
} => {
    const newColumns = JSON.parse(JSON.stringify(columns));
    let newDays: any[] = [];

    getUpdatedDays(columns, (daysToUpdate: any) => {
        const tempDays = JSON.parse(JSON.stringify(daysToUpdate));
        tempDays.splice(lesson.day - 1, 1, {
            ...tempDays[lesson.day - 1],
            lessons: tempDays[lesson.day - 1].lessons.map((data: any) => {
                const studentText = data.studentText.split(/<\/?(?:strong)[^>]*>\s*/im);
                const newText = studentText
                    .slice(1)
                    .map((text: string) => {
                        if (text.match(/<\/?p[^>]*>\s*/i)) {
                            return '<p>@empty text@</p>';
                        }

                        return `<p><strong>${text}</strong></p>`;
                    })
                    .join('');
                return {
                    ...data,
                    activities: [],
                    studentText: `${newText}`,
                    teacherText: '',
                };
            }),
        });
        newDays = tempDays;
    });

    newColumns[index] = newColumns[index].map((item: any) => {
        return {
            ...item,
            content: '',
        };
    });

    return {
        days: newDays,
        columns: newColumns,
    };
};

export const onDragEnd = (result: any, columns: any, lessonNames: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    const [columnIndex, lessonSubName, columnId, lessonId, lessonIndex] =
        source.droppableId.split('-');
    const [destColumnIndex, destLessonSubName, destColumnId, destLessonId, destLessonIndex] =
        destination.droppableId.split('-');
    const newColumns = JSON.parse(JSON.stringify(columns));
    const sourceItems = newColumns[columnIndex];
    const destItems = newColumns[destColumnIndex];
    const sourceIndex = sourceItems.findIndex((data: any) => {
        return (
            data.head === lessonSubName &&
            data.columnId === +columnId &&
            data.lessonId === +lessonId &&
            data.id === draggableId
        );
    });

    if (lessonIndex !== destLessonIndex) {
        const [removed] = sourceItems.splice(sourceIndex, 1);

        const gap = columnIndex === destColumnIndex ? 1 : 0;

        destItems.splice(destination.index - gap, 0, {
            ...removed,
            head: destLessonSubName,
            columnId: +destColumnId,
            lessonId: +destLessonId,
        });
        setColumns(newColumns);
    } else if (source.droppableId !== destination.droppableId) {
        const { id: destId } =
            destItems.filter((data: any) => {
                return (
                    data.head === destLessonSubName &&
                    data.columnId === +destColumnId &&
                    data.lessonId === +destLessonId
                );
            })[destination.index] || {};
        const destIndex =
            destItems.findIndex((data: any) => {
                return (
                    data.head === destLessonSubName &&
                    data.columnId === +destColumnId &&
                    data.lessonId === +destLessonId &&
                    data.id === destId
                );
            }) || destination.index;
        const [removed] = sourceItems.splice(sourceIndex, 1);
        destItems.splice(destIndex, 0, {
            ...removed,
            head: destLessonSubName,
            columnId: +destColumnId,
            lessonId: +destLessonId,
        });
        setColumns(newColumns);
    } else {
        const [removed] = sourceItems.splice(sourceIndex, 1);
        sourceItems.splice(sourceIndex - source.index + destination.index, 0, removed);
        setColumns(newColumns);
    }
};

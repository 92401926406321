import { AimMaterialType } from 'src/redux/coursePlanner/types';

export type FlatCategory = { name: string; subName: string; aims: number[]; level: string };

const getFlatCategories = (categories: AimMaterialType) => {
    const tempCategoryItems: FlatCategory[] = [];
    categories.categories.map((category) => {
        const categoryName = category.name;
        category.subCategories.map((subCategory) => {
            const subCatName = subCategory.name;
            subCategory.levels.map((level) => {
                tempCategoryItems.push({
                    name: categoryName,
                    subName: subCatName,
                    aims: level.aims,
                    level: level.name,
                });
                return false;
            });
            return false;
        });
        return false;
    });
    return tempCategoryItems;
};

export const newAssignmentsGetSearchResult = ({
    categories,
    level,
    searchText,
}: {
    categories: AimMaterialType;
    level: string;
    searchText: string;
}) => {
    const flatCategories = getFlatCategories(categories);
    const loverCaseSearchText = searchText?.toLowerCase();

    const valueMustFiltered: { [key: string]: false } = {};
    Object.entries({ level, searchText }).forEach(([key, value]) => {
        if (value) {
            valueMustFiltered[key] = false;
        }
    });

    return flatCategories.filter((category: FlatCategory) => {
        const filterFlag = structuredClone(valueMustFiltered);
        if ('level' in filterFlag) {
            filterFlag.level = category.level.includes(level);
        }
        if ('searchText' in filterFlag) {
            filterFlag.searchText =
                category.name.toLowerCase().includes(loverCaseSearchText) ||
                category.subName.toLowerCase().includes(loverCaseSearchText);
        }
        const unicFiltered = new Set(Object.values(filterFlag));
        return unicFiltered.size === 1 && unicFiltered.values().next().value;
    });
};

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CustomLink from 'src/components/CustomLink';
import { HeaderButtonProps } from './types';
import './styles.scss';

const HeaderButton = ({
    to,
    children,
    onClick,
    active,
    infoText,
    disabled,
    target,
}: HeaderButtonProps) => {
    const inner = () => {
        return (
            <div
                className={classNames('header-button__item pr_hover_color_text_svg_bg', {
                    'header-button__item_active': active,
                    disabled,
                })}
            >
                {children}
                {infoText ? (
                    <>
                        <div className="header-button-info__text">
                            <div className="header-button-info__text-parent">
                                <div>{infoText}</div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        );
    };
    if (to) {
        if (disabled) {
            return (
                <div
                    className={classNames('header-button cursor-pointer', {
                        'cursor-not-allowed disabled': disabled,
                    })}
                    data-testid="headerButton"
                    onClick={() => !disabled && onClick && onClick()}
                >
                    {inner()}
                </div>
            );
        }
        return (
            <CustomLink
                to={to}
                target={target}
                onClick={() => !disabled && onClick && onClick()}
                className={classNames('header-button cursor-pointer hideUnderline', {
                    'cursor-not-allowed disabled': disabled,
                })}
                data-testid="headerButton"
            >
                {inner()}
            </CustomLink>
        );
    }
    return (
        <div
            className={classNames('header-button cursor-pointer', {
                'cursor-not-allowed disabled': disabled,
            })}
            data-testid="headerButton"
            onClick={() => !disabled && onClick && onClick()}
        >
            {inner()}
        </div>
    );
};
HeaderButton.defaultProps = {
    active: false,
    infoText: null,
};
export default HeaderButton;

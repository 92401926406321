import { v4 as uuidv4 } from 'uuid';
import { appConstants } from 'src/constants';

export const getCol = (dataText: any) => {
    if (!dataText) {
        return [];
    }
    const studentData = [] as any;
    const a = dataText.match(/<\/?(?:strong)[^>]*>\s*/im)
        ? dataText
        : `<strong></strong>${dataText}`;

    const b = a.split(/<\/?(?:strong)[^>]*>\s*/im);
    const c = b.filter((text: string) => {
        if (text.match(/<\/?p[^>]*>\s*/i)) {
            return text.replace(/<\/?p[^>]*>\s*/im, '');
        }
        return true;
    });

    c.reduce((prev: string, next: string) => {
        studentData.push(
            (next || `@empty text@`)
                .split(/<\/?p[^>]*>\s*/im)
                .filter((text: string) => text)
                .map((text: string) => {
                    return {
                        content: text.replace(`@empty text@`, ''),
                        id: uuidv4(),
                    };
                }),
        );
        return '';
    }, '');
    return studentData.flat().filter((data: any) => data.content);
};

export const homeworkAddResourcesFromState = ({
    state,
    currentLesson,
    setIsDirty,
    setUpdateLesson,
}: {
    state: any;
    currentLesson: any;
    setIsDirty: any;
    setUpdateLesson: any;
}) => {
    if (state && state.addToResources && currentLesson) {
        setIsDirty(true);
        state.addToResources.forEach((curAddToResources: any) => {
            const tempCurAddToResources = structuredClone(curAddToResources);
            tempCurAddToResources.currentlyAdded = true;
            setUpdateLesson((data: any) => {
                const newActivities = data.activities
                    .map(({ id }: any) => id)
                    .includes(tempCurAddToResources.id)
                    ? data.activities
                    : [...data.activities, tempCurAddToResources];
                if (!data.teacherText.includes(`<p>${tempCurAddToResources.description}</p>`)) {
                    return {
                        ...data,
                        activities: newActivities,
                        teacherText: `${data.teacherText}<p>${tempCurAddToResources.description}</p>`,
                    };
                }
                return {
                    ...data,
                    activities: newActivities,
                };
            });
        });
    }
};

export const homeworkPrepareAssignmentForSave = ({
    data,
    assignment,
}: {
    data: any;
    assignment: any;
}) => {
    const tempAssignment = {
        ...assignment,
        type: 'assignment',
        currentlyAdded: true,
        preview: `${appConstants.server}/administration/assignments/reportswitchboard/${assignment.id}/1`,
    };
    return {
        ...data,
        activities: [...data.activities, tempAssignment],
    };
};

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import './styles.scss';
import WhiteBox from 'src/components/WhiteBox';
import { selectUnits, selectWeeklyPlan } from 'src/redux/weeklyPlan/selectors';
import Add from 'src/components/Icons/Add';
import { getElearning } from 'src/redux/weeklyPlan/actions';
import Tooltip from 'src/components/Tooltip';
import { useCallbackPrompt } from 'src/hooks/useCallbackPrompt';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { useTranslation } from 'react-i18next';
import HomeworkNotes from '../HomeworkNotes';
import MyAssignments from '../MyAssigments';
import ELearning from '../ELearning';
import { homeworkAddResourcesFromState } from '../helpers';

const DesktopHomework = () => {
    const params = useParams();
    const { lessons, days } = useSelector(selectWeeklyPlan) as any;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const state = useLocation().state as any;
    const units = useSelector(selectUnits);
    const [tab, setTab] = useState('assignments');
    const [activeEx, setActiveEx] = useState({} as any);
    const dispatch = useDispatch();
    const [tooltip, setTooltip] = useState(true);
    const weekly = useSelector(selectWeeklyPlan) as any;
    const { columnId, lessonId } =
        lessons
            ?.find(({ day }: any) => day === +params.day!)
            ?.lessons.find(({ name }: any) => /homework/i.test(name.trim())) || {};
    const currentLesson = days
        ?.find(({ day }: any) => day === +params.day!)
        ?.lessons.find(
            ({ columnId: colId, lessonId: lesId }: any) => columnId === colId && lessonId === lesId,
        );
    const [isDirty, setIsDirty] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty) as any;
    const [showPromptModal, setShowPromptModal] = useState(false as any);

    const [updateLesson, setUpdateLesson] = useState(currentLesson);

    useEffect(() => {
        if (state && state.tab) {
            setTab(state.tab);
        }
    }, [state]);

    useEffect(() => {
        if (activeEx.id) {
            dispatch(
                getElearning({
                    classId: params.id,
                    id: activeEx.id,
                }),
            );
        }
    }, [activeEx.id, params.id]);

    useEffect(() => {
        setShowPromptModal(showPrompt);
    }, [showPrompt]);

    const allSyllabus = useMemo(() => {
        const currentDayLessons = days?.find(({ day }: any) => day === +params.day!)?.lessons;
        let temp: any = [];
        temp = currentDayLessons?.map((val: any, key: any) => {
            return val?.syllabus.map(({ id }: any) => id);
        });
        return temp?.flat();
    }, [currentLesson]);

    const currentUnits = useMemo(() => {
        return units
            .map(({ name, pages }: any) => {
                return {
                    name,
                    pages: pages.filter(({ id }: any) => allSyllabus?.includes(id)),
                };
            })
            .filter(({ pages }: any) => pages.length);
    }, [units, allSyllabus]);

    useEffect(() => {
        if (currentUnits[0]) {
            setActiveEx(state && state.activeEx ? state.activeEx : currentUnits[0].pages[0]);
            setTab('learning');
        }
    }, [currentUnits]);

    useEffect(() => {
        if (state && state.updateLesson) {
            setIsDirty(true);
            setUpdateLesson(state.updateLesson);
        } else {
            setUpdateLesson(currentLesson);
        }

        homeworkAddResourcesFromState({ state, currentLesson, setIsDirty, setUpdateLesson });
    }, [currentLesson, state]);

    return (
        <div className="homework">
            <div className="homework-step1">
                <span className="homework-title">
                    {t('homework.desktop.step1')}
                    <span className="homework-title__desc">
                        {t('homework.desktop.itemOptionsSelection')}
                    </span>
                </span>

                <WhiteBox styles={{ overflow: 'unset' }}>
                    <div className="homework-step1-content">
                        {currentUnits.map(({ name, pages }: any) => (
                            <div key={name} className="homework-unit">
                                <span className="homework-unit-name">
                                    {`${t('homework.desktop.unit')} ${name}`}
                                </span>
                                {pages.map((ex: any) => (
                                    <span
                                        key={ex.id}
                                        className={classNames('homework-unit-exercise', {
                                            'homework-unit-exercise--active pr_hover_color_text_svg_bg':
                                                ex.id === activeEx.id,
                                            sc_hover_color_text_svg: ex.id !== activeEx.id,
                                        })}
                                        onClick={() => {
                                            setActiveEx(ex);
                                        }}
                                    >
                                        {ex.exName}
                                        {tooltip && (
                                            <div className="homework-unit-exercise__tooltip">
                                                <Tooltip
                                                    desc={t(
                                                        'homework.desktop.todaysLessonExercises',
                                                    )}
                                                    styles={{ left: '' }}
                                                    closeCallback={() => setTooltip(false)}
                                                />
                                            </div>
                                        )}
                                    </span>
                                ))}
                            </div>
                        ))}
                    </div>
                </WhiteBox>
            </div>
            <div className="homework-step2">
                <span className="homework-title">
                    {t('homework.desktop.step2')}
                    <span className="homework-title__desc">
                        {t('homework.desktop.selectionOptions')}
                    </span>
                </span>
                <WhiteBox>
                    <div className="homework-tabBar">
                        <div className="homework-tabs">
                            <span
                                className={classNames('homework-tab sc_hover_color_text_svg', {
                                    'homework-tab--active': tab === 'learning',
                                })}
                                onClick={() => setTab('learning')}
                            >
                                {t('homework.desktop.eLearning')}
                                <Tooltip
                                    desc="These are recommendations for e-learning based on the selected exercise in today’s lesson."
                                    styles={{ left: '-134px', width: '350px' }}
                                />
                            </span>
                            <span
                                className={classNames('homework-tab sc_hover_color_text_svg', {
                                    'homework-tab--active': tab !== 'learning',
                                })}
                                onClick={() => setTab('assignments')}
                            >
                                {t('homework.desktop.myAssignments')}
                                <Tooltip
                                    desc={t('homework.desktop.previousAssignmentsListInstructions')}
                                    styles={{ left: '-102px', width: '350px' }}
                                />
                            </span>
                        </div>
                        <div
                            className="homework-create pr_hover_color_text_svg_bg"
                            onClick={() => {
                                navigate(
                                    `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}/assigment`,
                                    {
                                        state: {
                                            activeEx,
                                            addToResources:
                                                state && 'addToResources' in state
                                                    ? state.addToResources
                                                    : [],
                                            updateLesson,
                                        },
                                    },
                                );
                                confirmNavigation();
                            }}
                        >
                            <Add />
                            <span>{t('homework.desktop.createMyOwn')}</span>
                        </div>
                    </div>

                    {tab === 'learning' ? (
                        <ELearning
                            setIsDirty={setIsDirty}
                            setUpdateLesson={(data) => setUpdateLesson(data)}
                            activeEx={activeEx}
                            updateLesson={updateLesson}
                        />
                    ) : (
                        <MyAssignments
                            setUpdateLesson={(data) => setUpdateLesson(data)}
                            updateLesson={updateLesson}
                        />
                    )}
                </WhiteBox>
            </div>
            <div className="homework-step3">
                <span className="homework-title">
                    {t('homework.desktop.step3')}{' '}
                    <span className="homework-title__desc">
                        {t('homework.desktop.addNotesAndSave')}
                    </span>
                </span>
                <HomeworkNotes
                    setIsDirty={setIsDirty}
                    currentLesson={updateLesson}
                    currentUnits={currentUnits}
                    setUpdateLesson={(data) => setUpdateLesson(data)}
                />
            </div>
            <ConfirmModal
                text={t('homework.desktop.unsavedChangesConfirmation')}
                onClick={() => {
                    confirmNavigation();
                }}
                onClose={() => {
                    setShowPromptModal(false);
                    cancelNavigation();
                }}
                open={showPromptModal}
            />
        </div>
    );
};

export default DesktopHomework;

import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'src/hooks/useWindowSize';
import Search from 'src/components/Search';
import Arrow from 'src/components/Arrow';
import SelectMenu from 'src/components/SelectMenu';
import './styles.scss';
import { getElearning, putElearning } from 'src/redux/weeklyPlan/actions';
import NoResults from 'src/components/NoResults';
import { selectAimsMaterials } from 'src/redux/coursePlanner/selectors';
import { getAimsMaterials } from 'src/redux/coursePlanner/actions';
import { selectElernings } from 'src/redux/weeklyPlan/selectors';
import { selectLoading } from 'src/redux/loading/selectors';
import ArrowThin from 'src/components/Icons/ArrowThin';
import NewAssignmentsELearningActivities from './NewAssignmentsELearningActivities';
import NewAssignmentsELearningInfo from './NewAssignmentsELearningInfo';
import { useNewAssignmentsFilterHelper } from './newAssignmentsFilterHelper';
import { FlatCategory, newAssignmentsGetSearchResult } from './NewAssignmentsGetSearchResult';

export const NEW_ASSIGNMENTS_LEVEL_DEFAULT_NAME = 'Level';

type Level = {
    name: string;
    id: null | string;
};
const NewAssignmentsELearning = () => {
    const loading = useSelector(selectLoading);
    const eLearning = useSelector(selectElernings);
    const state = useLocation().state as any;
    const [searchLevels, setSearchLevels] = useState<Level[]>([]);
    const [filters, setFilters] = useState({} as any);
    const [titles, setTitles] = useState({} as any);
    const [searchParams] = useSearchParams();
    const { updateFilter, updateTitle, deleteFilter } = useNewAssignmentsFilterHelper({
        filters,
        setFilters,
        titles,
        setTitles,
    });
    const aimsMaterials = useSelector(selectAimsMaterials);
    const [activeSearchResult, setActiveSearchResult] = useState<string | null>(null);
    const dispatch = useDispatch();
    const [searchResults, setSearchResults] = useState<FlatCategory[] | null>(null);
    const [category, setCategory] = useState('');
    const [area, setArea] = useState<string | null>(null);
    const [level, setLevel] = useState<string | null>(null);
    const params = useParams();
    const [num, setNum] = useState(0);
    const getSearchLevels = () => {
        const levels = new Set<string>();
        aimsMaterials.categories.map((item) => {
            item.subCategories.map((subC) => {
                subC.levels.map((lev) => {
                    levels.add(lev.name);
                    return false;
                });
                return false;
            });
            return false;
        });
        const tempLevelsObj: Level[] = [{ name: NEW_ASSIGNMENTS_LEVEL_DEFAULT_NAME, id: null }];
        Array.from(levels).forEach((value) => {
            tempLevelsObj.push({ name: value, id: value });
        });
        return tempLevelsObj;
    };

    const [step, setStep] = useState(1);
    const { isDesktop } = useWindowSize();
    const { t } = useTranslation();

    useEffect(() => {
        setSearchLevels(getSearchLevels());
    }, [aimsMaterials]);
    useEffect(() => {
        dispatch(getAimsMaterials({ classId: params.id }));
    }, [dispatch]);

    const searchEmpty = !searchResults?.length && Object.values(filters)?.length;
    const searchNotEmpty = searchResults?.length && Object.values(filters)?.length;
    const categoryLevelItems = (
        <div className="NewAssignmentsEL-category__items">
            {aimsMaterials.categories
                .find((categoryForFind) => {
                    return categoryForFind.name === category;
                })
                ?.subCategories.find((currentSubCategory) => {
                    return currentSubCategory.name === area;
                })
                ?.levels.map((currentLevel, currentLevelIndex) => {
                    return (
                        <div
                            key={`${currentLevel.name}${currentLevelIndex}`}
                            className={classNames('NewAssignmentsEL-category__item', {
                                active: currentLevel.name === level,
                            })}
                            onClick={() => {
                                setNum(0);
                                setLevel(currentLevel.name);
                                dispatch(
                                    getElearning({
                                        classId: params.id,
                                        id: currentLevel.aims,
                                        type: 'aims',
                                    }),
                                );
                            }}
                        >
                            {currentLevel.name}
                            <div className="NewAssignmentsEL-category-item-arrow">
                                <ArrowThin />
                            </div>
                        </div>
                    );
                })}
        </div>
    );
    const isDesktopsOrStep3 = isDesktop || step === 3;
    return (
        <div
            className={classNames('NewAssignmentsEL-primary-parent', {
                'search-empty': searchEmpty,
                'search-not-empty': searchNotEmpty,
            })}
        >
            <div className="NewAssignmentsEL-search">
                <div className="NewAssignmentsEL-search__inputs">
                    <div className="NewAssignmentsEL-search__level">
                        <SelectMenu
                            alwaysWide
                            wrapperClass="type_3"
                            options={searchLevels.map((searchLevel: any) => {
                                return {
                                    content: (
                                        <div title={searchLevel.name}>
                                            <span>{searchLevel.name}</span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        if (searchLevel.id === null) {
                                            deleteFilter(['level']);
                                            updateTitle({ level: searchLevel.name });
                                        } else {
                                            updateFilter({ level: searchLevel.id });
                                            updateTitle({ level: searchLevel.name });
                                        }
                                        dispatch(putElearning({}));
                                        setSearchResults(
                                            newAssignmentsGetSearchResult({
                                                categories: aimsMaterials,
                                                level: searchLevel.id,
                                                searchText: filters.search,
                                            }),
                                        );
                                    },
                                };
                            })}
                            text={titles.level ?? NEW_ASSIGNMENTS_LEVEL_DEFAULT_NAME}
                        />
                    </div>
                    <div className="NewAssignmentsEL-search__horizontal-line" />
                    <div className="NewAssignmentsEL-search__text-search">
                        <Search
                            key={JSON.stringify(aimsMaterials)}
                            defaultValue={(searchParams.get('searchTerm') || '').replace(/_/g, ' ')}
                            onPushEnter={() => {
                                setSearchResults(
                                    newAssignmentsGetSearchResult({
                                        categories: aimsMaterials,
                                        level: filters.level,
                                        searchText: filters.search,
                                    }),
                                );
                                setStep(1);
                                setNum(0);
                                setCategory('');
                                setArea(null);
                                setLevel(null);
                                setActiveSearchResult('');
                                dispatch(putElearning({}));
                            }}
                            fullWidth
                            onClearButton={() => {
                                setStep(1);
                                setNum(0);
                                setCategory('');
                                setArea(null);
                                setLevel(null);
                                dispatch(putElearning({}));
                                deleteFilter(['search', 'level']);
                                updateTitle({ level: searchLevels[0]?.name });
                            }}
                            alwaysWide
                            onChange={(text: string) => {
                                setStep(1);
                                setNum(0);
                                setCategory('');
                                setArea(null);
                                setLevel(null);
                                setActiveSearchResult('');
                                dispatch(putElearning({}));
                                if (text === '') {
                                    deleteFilter(['search']);
                                } else {
                                    updateFilter({ search: text });
                                    setSearchResults(
                                        newAssignmentsGetSearchResult({
                                            categories: aimsMaterials,
                                            level: filters.level,
                                            searchText: text,
                                        }),
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            {eLearning[num] && !searchResults && level ? (
                <NewAssignmentsELearningInfo num={num} eLearning={eLearning} setNum={setNum} />
            ) : (
                <div />
            )}
            {searchEmpty && !loading ? (
                <NoResults
                    title={t('NewAssigment.noResultsFound')}
                    subtitle={t('NewAssigment.tryDifferentSearchTermOrClear')}
                />
            ) : (
                <>
                    <div className="NewAssignmentsEL-content">
                        {searchResults && Object.values(filters).length ? (
                            <>
                                <div className="NewAssignmentsEL-search-results">
                                    {searchResults &&
                                        searchResults.map((searchResult) => {
                                            return (
                                                <div
                                                    key={`${searchResult.name}_${searchResult.subName}_${searchResult.level}`}
                                                    className={classNames(
                                                        'NewAssignmentsEL-search-results-item cursor-pointer',
                                                        {
                                                            active:
                                                                `${searchResult.name}_${searchResult.subName}_${searchResult.level}` ===
                                                                activeSearchResult,
                                                        },
                                                    )}
                                                    onClick={() => {
                                                        setNum(0);
                                                        setActiveSearchResult(
                                                            `${searchResult.name}_${searchResult.subName}_${searchResult.level}`,
                                                        );
                                                        dispatch(
                                                            getElearning({
                                                                classId: params.id,
                                                                id: searchResult.aims,
                                                                type: 'aims',
                                                            }),
                                                        );
                                                    }}
                                                >
                                                    <div className="NewAssignmentsEL-search-results-items__sub-category">
                                                        {searchResult.subName}
                                                    </div>
                                                    <div className="NewAssignmentsEL-search-results-items__desc">
                                                        <span>{searchResult.name}</span> -{' '}
                                                        <span>{searchResult.level}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                                {isDesktop ? (
                                    <></>
                                ) : (
                                    <div className="NewAssignmentsEL-search-results-description">
                                        <NewAssignmentsELearningInfo
                                            num={num}
                                            eLearning={eLearning}
                                            setNum={setNum}
                                        />
                                        <NewAssignmentsELearningActivities
                                            eLearning={eLearning}
                                            num={num}
                                            state={state}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="NewAssignmentsEL-categories">
                                {(isDesktop || step === 1) && (
                                    <div className="NewAssignmentsEL-category category">
                                        <div className="NewAssignmentsEL-category__title">
                                            <span>{t('NewAssigment.choose')}</span>
                                            <h2>{t('NewAssigment.category')}</h2>
                                        </div>
                                        <div className="NewAssignmentsEL-category__items">
                                            {aimsMaterials.categories.map(
                                                (currentCategory, currentCategoryIndex) => {
                                                    return (
                                                        <span
                                                            key={`${currentCategory.name}${currentCategoryIndex}`}
                                                            className={classNames(
                                                                'NewAssignmentsEL-category__item',
                                                                {
                                                                    active:
                                                                        category ===
                                                                        currentCategory.name,
                                                                },
                                                            )}
                                                            onClick={() => {
                                                                dispatch(putElearning({}));
                                                                setCategory(currentCategory.name);
                                                                setStep(2);
                                                                setNum(0);
                                                                setArea(null);
                                                                setLevel(null);
                                                            }}
                                                        >
                                                            {currentCategory.name}
                                                            <div className="NewAssignmentsEL-category-item-arrow">
                                                                <ArrowThin />
                                                            </div>
                                                        </span>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                )}
                                {(isDesktop || step === 2) && (
                                    <div className="NewAssignmentsEL-category area">
                                        <div className="NewAssignmentsEL-category__title">
                                            <Arrow
                                                onClick={() => {
                                                    dispatch(putElearning({}));
                                                    setStep(1);
                                                }}
                                                direction="left"
                                                disabled={false}
                                            />
                                            <span>{t('NewAssigment.choose')}</span>
                                            <h2>{t('NewAssigment.area')}</h2>
                                        </div>
                                        <div className="NewAssignmentsEL-category__items">
                                            {aimsMaterials.categories
                                                .find((categoryForFind) => {
                                                    return categoryForFind.name === category;
                                                })
                                                ?.subCategories.map((currentArea, areaIndex) => {
                                                    return (
                                                        <span
                                                            key={`${currentArea.name}${areaIndex}`}
                                                            className={classNames(
                                                                'NewAssignmentsEL-category__item',
                                                                {
                                                                    active:
                                                                        currentArea.name === area,
                                                                },
                                                            )}
                                                            onClick={() => {
                                                                dispatch(putElearning({}));
                                                                setNum(0);
                                                                setStep(3);
                                                                setArea(currentArea.name);
                                                                setLevel(null);
                                                            }}
                                                        >
                                                            {currentArea.name}
                                                            <div className="NewAssignmentsEL-category-item-arrow">
                                                                <ArrowThin />
                                                            </div>
                                                        </span>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                )}

                                {isDesktopsOrStep3 && (
                                    <div className="NewAssignmentsEL-category level">
                                        <div className="NewAssignmentsEL-category__title">
                                            <Arrow
                                                onClick={() => {
                                                    dispatch(putElearning({}));
                                                    setStep(2);
                                                }}
                                                direction="left"
                                                disabled={false}
                                            />
                                            <div>
                                                <span className="NewAssignmentsEL-category__title-chose">
                                                    {t('NewAssigment.choose')}
                                                </span>
                                                <h2>{t('NewAssigment.group')}</h2>
                                            </div>
                                            {!isDesktop ? categoryLevelItems : <></>}
                                        </div>
                                        {isDesktop ? categoryLevelItems : <></>}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {isDesktop || step === 3 ? (
                        <>
                            {eLearning.length ? (
                                <>
                                    <NewAssignmentsELearningInfo
                                        num={num}
                                        eLearning={eLearning}
                                        setNum={setNum}
                                    />
                                    <NewAssignmentsELearningActivities
                                        eLearning={eLearning}
                                        num={num}
                                        state={state}
                                    />
                                </>
                            ) : (
                                <>
                                    {(level || activeSearchResult) && !loading ? (
                                        <NoResults title={t('NewAssigment.noResults')} />
                                    ) : null}
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
};
export default NewAssignmentsELearning;

import { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getClass } from 'src/redux/classes/actions';
import { selectClass } from 'src/redux/classes/selectors';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import './styles.scss';
import { getAssigments, getWeeklyPlan } from 'src/redux/weeklyPlan/actions';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import DesktopHomework from './Desktop';
import MobileHomework from './Mobile';

const Homework = () => {
    const { width } = useWindowSize();
    const dispatch = useDispatch();
    const classData = useSelector(selectClass);
    const params = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    useEffect(() => {
        if (params.id && +params.id !== classData?.id) {
            dispatch(
                getClass({
                    classId: params.id,
                    cb: (data: any) => {
                        dispatch(
                            putBreadcrumbs([
                                {
                                    name: `${t('general.class')} ${data.name}`,
                                },
                            ]),
                        );
                    },
                }),
            );
        } else if (params.id && classData?.id) {
            dispatch(
                getWeeklyPlan({
                    year: params.year as unknown as number,
                    week: params.week as unknown as number,
                    id: params.id,
                }),
            );
            dispatch(
                getAssigments({
                    classId: params.id,
                }),
            );
            dispatch(
                putBreadcrumbs([
                    {
                        name: `${t('general.class')} ${classData.name}`,
                        link: `/weekly-plan/${params.id}`,
                    },

                    {
                        name: `${moment()
                            .day(+params.day! as unknown as number)
                            .isoWeekYear(+params.year! as unknown as number)
                            .isoWeek(+params.week! as unknown as number)
                            .format('dddd')} ${t('homework.homework')}`,
                    },
                ]),
            );
        }
    }, [dispatch, params, classData?.id]);

    if (width > 1179) {
        return <DesktopHomework />;
    }

    return <MobileHomework />;
};

export default Homework;

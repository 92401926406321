import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { putLayout, putModules } from '../actions';

export function* getLayoutSaga(): SagaIterator {
    try {
        const hexToRgb = (hex: string) => {
            const matchHex = hex
                .replace(
                    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                    (m: any, r: any, g: any, b: any) => `#${r}${r}${g}${g}${b}${b}`,
                )
                .substring(1)
                .match(/.{2}/g);
            return matchHex ? matchHex.map((x: any) => parseInt(x, 16)) : [];
        };

        yield put(startLoading());
        const data = yield call(API.get(), '/layout');
        Object.keys(data.styling).forEach((prop) => {
            let tempProp = data.styling[prop];
            if (prop.search(/Color$/) !== -1) {
                tempProp = hexToRgb(data.styling[prop]);
            }
            document.documentElement.style.setProperty(`--${prop}`, tempProp);
        });
        window.localStorage.setItem('school', JSON.stringify({ gid: data.school.gid }));
        localStorage.setItem('layout', JSON.stringify(data));
        yield put(putLayout(data));
        const modules = yield call(API.get(), '/staff/layout/modules');
        localStorage.setItem('modules', JSON.stringify(modules));
        yield put(putModules(modules));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}

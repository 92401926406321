import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CustomLink from 'src/components/CustomLink';
import CustomLinkOverloaded from 'src/components/CustomLink/CustomLinkOverloaded';

import { useClass } from 'src/hooks/useClass';
import { putSidebar } from 'src/redux/layout/actions';
import { PutAttendanceStudentsProps } from 'src/redux/attendance/types';
import { getAttendanceStudents, saveAttendance } from 'src/redux/attendance/actions';
import { selectAttendanceStudents } from 'src/redux/attendance/selectors';
import { useCallbackPrompt } from 'src/hooks/useCallbackPrompt';
import WhiteBox from 'src/components/WhiteBox';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import Search from 'src/components/Search';
import MobileControls from 'src/components/MobileControls';
import DateSelector from 'src/components/DateSelector';
import Table from 'src/components/Table';
import SelectMenu from 'src/components/SelectMenu';
import Save from 'src/components/Icons/Save';
import { AttendanceShortcut } from './types';

const Attendance = () => {
    const [showPromptModal, setShowPromptModal] = useState(false as any);
    const [{ week, year }, setDate] = useState({
        week: moment(new Date()).isoWeek(),
        year: moment(new Date()).year(),
    });
    const [dataToSave, setDataToSave] = useState<
        {
            courseWeeklyStructureId: number;
            attendanceType: AttendanceShortcut | null;
            studentId: number;
        }[]
    >([]);
    const [search, setSearch] = useState('');
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        !!dataToSave.length,
    ) as any;
    const studentsData = useSelector(selectAttendanceStudents);
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const students = useMemo(() => {
        return studentsData.filter(({ name }) => {
            return name.toLowerCase().includes(search.toLowerCase());
        });
    }, [studentsData, search]);

    useClass();

    useEffect(() => {
        setShowPromptModal(showPrompt);
    }, [showPrompt]);

    useEffect(() => {
        if (!dataToSave.length) {
            dispatch(
                getAttendanceStudents({
                    classId: params.id,
                    week,
                    year,
                }),
            );
        }
    }, []);

    useEffect(() => {
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [params]);

    const updateDataToSave = ({
        courseWeeklyStructureId,
        attendanceType,
        studentId,
    }: {
        courseWeeklyStructureId: number;
        attendanceType: AttendanceShortcut | null;
        studentId: number;
    }) => {
        const item = dataToSave.find(
            (i) =>
                i.studentId === studentId && i.courseWeeklyStructureId === courseWeeklyStructureId,
        );

        if (item) {
            setDataToSave((prevData) => [
                ...prevData.filter(
                    (i) =>
                        !(
                            i.studentId === item.studentId &&
                            i.courseWeeklyStructureId === item.courseWeeklyStructureId
                        ),
                ),
                {
                    courseWeeklyStructureId,
                    attendanceType,
                    studentId,
                },
            ]);
        } else {
            setDataToSave((prevData) => [
                ...prevData,
                {
                    courseWeeklyStructureId,
                    attendanceType,
                    studentId,
                },
            ]);
        }
    };

    const getWorkdaysWithFormattedDates = (w: number, y: number) => {
        const startDate = moment().isoWeekYear(y).isoWeek(w).isoWeekday(1);
        const endDate = moment().isoWeekYear(y).isoWeek(w).isoWeekday(5);
        const workdays = [];

        for (
            let current = startDate.clone();
            current.isSameOrBefore(endDate);
            current.add(1, 'days')
        ) {
            const formattedDate = {
                dayName: current.format('ddd').toUpperCase(),
                day: current.format('DD'),
                month: current.format('MMM'),
            };
            workdays.push(formattedDate);
        }

        return workdays;
    };

    const handleSave = () => {
        dispatch(
            saveAttendance({
                classId: params.id,
                attendance: dataToSave,
                week,
                year,
                cb: () => setDataToSave([]),
            }),
        );
    };

    const StudentLessonAttendanceSelect = ({
        student,
        attendanceType,
        courseWeeklyStructureId,
    }: {
        student: PutAttendanceStudentsProps;
        attendanceType: 'P' | 'A' | 'E' | 'L' | null;
        courseWeeklyStructureId: number;
    }) => {
        return (
            <SelectMenu
                wrapperClass="type_4"
                text={
                    dataToSave.find(
                        (i) =>
                            i.studentId === student.studentId &&
                            i.courseWeeklyStructureId === courseWeeklyStructureId,
                    )?.attendanceType ||
                    attendanceType ||
                    t('general.select')
                }
                options={[
                    {
                        content: (
                            <div>
                                <b>P</b> - {t('attendance.present')}
                            </div>
                        ),
                        onClick: () =>
                            updateDataToSave({
                                courseWeeklyStructureId,
                                attendanceType: AttendanceShortcut.Present,
                                studentId: student.studentId,
                            }),
                    },
                    {
                        content: (
                            <div>
                                <b>A</b> - {t('attendance.absent')}
                            </div>
                        ),
                        onClick: () =>
                            updateDataToSave({
                                courseWeeklyStructureId,
                                attendanceType: AttendanceShortcut.Absent,
                                studentId: student.studentId,
                            }),
                    },
                    {
                        content: (
                            <div>
                                <b>E</b> - {t('attendance.excused')}
                            </div>
                        ),
                        onClick: () =>
                            updateDataToSave({
                                courseWeeklyStructureId,
                                attendanceType: AttendanceShortcut.Excused,
                                studentId: student.studentId,
                            }),
                    },
                    {
                        content: (
                            <div>
                                <b>L</b> - {t('attendance.late')}
                            </div>
                        ),
                        onClick: () =>
                            updateDataToSave({
                                courseWeeklyStructureId,
                                attendanceType: AttendanceShortcut.Late,
                                studentId: student.studentId,
                            }),
                    },
                ]}
            />
        );
    };

    return (
        <>
            <WhiteBox>
                <div className="assessments-header">
                    <Search onChange={(text: string) => setSearch(text)} />
                    <div className="assessments-header-right">
                        <DateSelector
                            weekNumber={week}
                            year={year}
                            onChange={(weekNumber: number, yearNumber: number) => {
                                if (dataToSave.length) {
                                    setShowPromptModal({
                                        next: {
                                            year: yearNumber,
                                            week: weekNumber,
                                        },
                                        prev: { week, year },
                                    } as any);
                                    setDate({
                                        year: yearNumber,
                                        week: weekNumber,
                                    });
                                    return;
                                }

                                dispatch(
                                    getAttendanceStudents({
                                        classId: params.id,
                                        week: weekNumber,
                                        year: yearNumber,
                                    }),
                                );

                                setDate({
                                    week: weekNumber,
                                    year: yearNumber,
                                });
                                setDataToSave([]);
                            }}
                        />
                        <div
                            className="assessments-header-right-icon pr_hover_color_text_svg_bg"
                            onClick={() => handleSave()}
                        >
                            <Save />
                        </div>
                    </div>
                </div>

                <MobileControls>
                    <Search onChange={(text: string) => setSearch(text)} />
                    <DateSelector
                        weekNumber={week}
                        year={year}
                        onChange={(weekNumber: number, yearNumber: number) => {
                            if (dataToSave.length) {
                                setShowPromptModal({
                                    next: {
                                        year: yearNumber,
                                        week: weekNumber,
                                    },
                                    prev: { week, year },
                                } as any);
                                setDate({
                                    year: yearNumber,
                                    week: weekNumber,
                                });
                                return;
                            }

                            dispatch(
                                getAttendanceStudents({
                                    classId: params.id,
                                    week: weekNumber,
                                    year: yearNumber,
                                }),
                            );

                            setDate({
                                week: weekNumber,
                                year: yearNumber,
                            });
                            setDataToSave([]);
                        }}
                    />
                    <div
                        className="assessments-header-right-icon pr_hover_color_text_svg_bg"
                        onClick={() => handleSave()}
                    >
                        <Save />
                    </div>
                </MobileControls>
                <Table
                    header={
                        <>
                            <div style={{ width: '18.25rem' }}>{t('general.student')}</div>
                            {getWorkdaysWithFormattedDates(week, year).map(
                                ({ dayName, day, month }) => (
                                    <div
                                        key={`${dayName}_${day}_${month}`}
                                        style={{ width: '7.5rem', textAlign: 'center' }}
                                    >
                                        <b>{dayName}</b> - {day} {month}
                                    </div>
                                ),
                            )}
                            <div>
                                {t('attendance.overall')} <br /> {t('attendance.attendance')}
                            </div>
                        </>
                    }
                    headerMobile={<span className="assessments-name">{t('general.student')}</span>}
                    rows={students.map((student, index) => {
                        const isEven = (index + 1) % 2 === 0;

                        return (
                            <div
                                key={`${index}_${student.name}`}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: 'calc(100% + 24px)',
                                    marginLeft: '-12px',
                                    ...(isEven && { backgroundColor: '#FFF' }),
                                }}
                            >
                                <CustomLinkOverloaded
                                    linkProps={{
                                        to: `/students/${params.id}/${student.studentId}/profile`,
                                        className: 'assessments-name classes-blue',
                                        style: {
                                            width: '15rem',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        },
                                    }}
                                    parentProps={{
                                        className: 'assessments-name classes-blue',
                                        style: { display: 'contents' },
                                    }}
                                >
                                    {student.name}
                                </CustomLinkOverloaded>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                        width: '100%',
                                        padding: '0.5rem 0',
                                    }}
                                >
                                    {[...student.lessons]
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map(
                                            (
                                                {
                                                    name,
                                                    weeklyAttendance,
                                                    attendanceActualHours,
                                                    attendanceTotalHours,
                                                },
                                                lessonsIndex,
                                            ) => (
                                                <div
                                                    key={`${name}_${student.name}_${lessonsIndex}`}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            height: '45px',
                                                            width: '6rem',
                                                        }}
                                                    >
                                                        {name}
                                                    </div>

                                                    {weeklyAttendance
                                                        .slice()
                                                        .sort((a, b) => a.dayOfWeek - b.dayOfWeek)
                                                        .concat(
                                                            Array(5 - weeklyAttendance.length).fill(
                                                                {},
                                                            ),
                                                        )
                                                        .map(
                                                            (
                                                                {
                                                                    attendanceType,
                                                                    courseWeeklyStructureId,
                                                                },
                                                                weeklyAttendanceIndex,
                                                            ) => {
                                                                if (
                                                                    !attendanceType &&
                                                                    !courseWeeklyStructureId
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                weeklyAttendanceIndex
                                                                            }
                                                                            style={{
                                                                                width: '7.5rem',
                                                                            }}
                                                                        />
                                                                    );
                                                                }

                                                                return (
                                                                    <StudentLessonAttendanceSelect
                                                                        key={weeklyAttendanceIndex}
                                                                        student={student}
                                                                        attendanceType={
                                                                            attendanceType
                                                                        }
                                                                        courseWeeklyStructureId={
                                                                            courseWeeklyStructureId
                                                                        }
                                                                    />
                                                                );
                                                            },
                                                        )}
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '45px',
                                                            width: '5.25rem',
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {(
                                                            (attendanceActualHours /
                                                                attendanceTotalHours) *
                                                                100 || 0
                                                        ).toFixed(0)}
                                                        %
                                                    </div>
                                                </div>
                                            ),
                                        )}
                                </div>
                            </div>
                        );
                    })}
                    rowsMobile={students.map((student, studentIndex) => ({
                        head: (
                            <div
                                className="assessments-name-mobile assessments-active"
                                style={{
                                    maxWidth: 'calc(100vw - 3rem)',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                }}
                            >
                                <b>{studentIndex + 1}.</b>
                                {student.name}
                            </div>
                        ),
                        content: (
                            <>
                                {[0, 1, 2, 3, 4].map((dayIndex) => {
                                    const isEven = (dayIndex + 1) % 2 === 0;
                                    const daysData = getWorkdaysWithFormattedDates(week, year);

                                    return (
                                        <div
                                            key={dayIndex}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: 'calc(100% + 24px)',
                                                marginLeft: '-12px',
                                                padding: '0 1rem',
                                                background: isEven
                                                    ? '#FFF'
                                                    : '#EFEFEF 0 0 no-repeat padding-box',
                                            }}
                                        >
                                            <div>
                                                <b>{daysData[dayIndex].dayName}</b> -{' '}
                                                {daysData[dayIndex].day} {daysData[dayIndex].month}
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    gap: '0.5rem',
                                                    padding: '0.5rem 0',
                                                }}
                                            >
                                                {[...student.lessons]
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .map(({ name, weeklyAttendance }) => {
                                                        const {
                                                            attendanceType,
                                                            courseWeeklyStructureId,
                                                        } =
                                                            weeklyAttendance.find(
                                                                (attendance) =>
                                                                    attendance.dayOfWeek ===
                                                                    dayIndex + 1,
                                                            ) || {};

                                                        if (!courseWeeklyStructureId) {
                                                            return (
                                                                <div
                                                                    key={`${name}_${student.name}_${dayIndex}`}
                                                                    style={{ height: '45px' }}
                                                                />
                                                            );
                                                        }

                                                        return (
                                                            <div
                                                                key={`${name}_${student.name}_${dayIndex}`}
                                                                style={{
                                                                    display: 'flex',
                                                                    gap: '1rem',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'flex-end',
                                                                        width: '2rem',
                                                                    }}
                                                                >
                                                                    {name}
                                                                </div>
                                                                <StudentLessonAttendanceSelect
                                                                    student={student}
                                                                    attendanceType={
                                                                        attendanceType || null
                                                                    }
                                                                    courseWeeklyStructureId={
                                                                        courseWeeklyStructureId || 0
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    );
                                })}
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '1rem',
                                        width: 'calc(100% + 24px)',
                                        marginLeft: '-12px',
                                        padding: '1rem',
                                        backgroundColor: '#FFF',
                                    }}
                                >
                                    <div>{t('attendance.overallAttendance')}:</div>
                                    {student.lessons.map(
                                        (
                                            { name, attendanceActualHours, attendanceTotalHours },
                                            lessonsIndex,
                                        ) => (
                                            <b key={`${lessonsIndex}_${name}`}>
                                                {name}:{' '}
                                                {(
                                                    (attendanceActualHours / attendanceTotalHours) *
                                                        100 || 0
                                                ).toFixed(0)}
                                                %
                                            </b>
                                        ),
                                    )}
                                </div>
                            </>
                        ),
                    }))}
                />
            </WhiteBox>
            <ConfirmModal
                text={t('general.unsavedChangesConfirmation')}
                onClick={() => {
                    if (typeof showPromptModal === 'object') {
                        setDate(showPromptModal.next);
                        setShowPromptModal(false);
                        setDataToSave([]);
                        return;
                    }
                    confirmNavigation();
                }}
                onClose={() => {
                    if (typeof showPromptModal === 'object') {
                        setDate(showPromptModal.prev);
                        setShowPromptModal(false);
                        return;
                    }
                    cancelNavigation();
                }}
                open={showPromptModal}
            />
        </>
    );
};
export default Attendance;

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selecAssigments } from 'src/redux/weeklyPlan/selectors';
import Checkbox from 'src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { AssigmentProps } from './types';
import { homeworkPrepareAssignmentForSave } from '../helpers';
import './styles.scss';

const MyAssignments = ({ setUpdateLesson, updateLesson }: AssigmentProps) => {
    const state = useLocation().state as any;
    const { t } = useTranslation();
    const assignments = useSelector(selecAssigments);
    const isCurrentAssignmentAdded = ({ assignment }: { assignment: any }) => {
        return updateLesson?.activities?.map(({ id }: any) => id).includes(assignment?.id);
    };

    useEffect(() => {
        if (state && state.addAssignment && updateLesson && assignments[0]) {
            if (!updateLesson?.activities?.map(({ id }: any) => id).includes(assignments[0]?.id)) {
                setUpdateLesson((data: any) =>
                    homeworkPrepareAssignmentForSave({ data, assignment: assignments[0] }),
                );
                delete state.tab;
                delete state.addAssignment;
            }
        }
    }, [updateLesson]);

    return (
        <div className="assigment">
            <div className="assigment-head">
                <span>{t('homework.myAssignments.title')}</span>
                <span>{t('homework.myAssignments.type')}</span>
                <span>{t('homework.myAssignments.addToHomework')}</span>
            </div>

            <div className="assigment-body">
                {assignments.map((assignment: any) => {
                    return (
                        <div key={assignment.id} className="assigment-row">
                            <span>{assignment.name}</span>
                            <span>{assignment.type}</span>
                            {isCurrentAssignmentAdded({ assignment }) ? (
                                <span className="assigment-added">
                                    <Checkbox checked />
                                    {t('homework.myAssignments.added')}
                                </span>
                            ) : (
                                <span
                                    className="sc_hover_color_text_svg"
                                    onClick={() => {
                                        setUpdateLesson((data: any) =>
                                            homeworkPrepareAssignmentForSave({ data, assignment }),
                                        );
                                    }}
                                >
                                    {t('homework.myAssignments.add')}
                                </span>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MyAssignments;

import { Route, Routes, unstable_HistoryRouter as BrowserRouter } from 'react-router-dom';
import Management from './pages/Management';
import Layout from './containers/Layout';
import Students from './pages/Students';
import WeeklyPlan from './pages/WeeklyPlan';
import Assessments from './pages/Assessments';
import Attendance from './pages/Attendance';
import OnlineTesting from './pages/OnlineTesting';
import AutomatedTest from './pages/AutomatedTest';
import Reports from './pages/Reports';
import NotFoundPage from './pages/NotFoundPage';
import Classes from './pages/Classes';
import ApiErrorModal from './components/Modals/ApiErrorModal';
import Rollbar from './containers/Rollbar';
import Invite from './pages/Invite';
import Homework from './pages/Homework';
import NewAssigment from './pages/NewAssigment';
import AllStudents from './pages/AllStudents';
import StaffReports from './pages/StaffReports';
import Emails from './pages/Emails';
import QRCodes from './pages/QrCodes';
import DuplicateStudents from './pages/DuplicateStudents';
import UnmatchedStudents from './pages/UnmatchedStudents';
import history from './history';
import Profile from './pages/Profile';
import { RemoveTrailingSlash } from './RemoveTrailingSlash';
import ToDoList from './pages/ToDoList';
import { Modules, useIsModuleActive } from './hooks/useIsModuleActive';

export default function Router() {
    const { isModuleActive } = useIsModuleActive();

    const content = (
        <BrowserRouter history={history as any}>
            <Layout>
                <Rollbar>
                    <RemoveTrailingSlash />
                    <Routes>
                        <>
                            {isModuleActive(Modules.Classes) && (
                                <Route path="/" element={<Classes />} />
                            )}

                            {isModuleActive(Modules.ClassWeeklyPlan) && (
                                <Route path="/weekly-plan/:id" element={<WeeklyPlan />} />
                            )}
                            <Route
                                path="/weekly-plan/:id/:year/:week/:day"
                                element={<Homework />}
                            />
                            <Route
                                path="/weekly-plan/:id/:year/:week/:day/assigment"
                                element={<NewAssigment />}
                            />

                            {isModuleActive(Modules.ClassReports) && (
                                <Route path="/reports/:id" element={<Reports />} />
                            )}

                            {isModuleActive(Modules.ClassStudents) && (
                                <Route path="/students/:id" element={<Students />} />
                            )}
                            <Route
                                path="/students/:classId/:id/:tab"
                                element={<Profile match="/students/:classId/:id/:tab" />}
                            />

                            {isModuleActive(Modules.ClassAssessments) && (
                                <Route path="/assessments/:id" element={<Assessments />} />
                            )}

                            {isModuleActive(Modules.ClassTests) && (
                                <Route
                                    path="/online-testing/:type/:id"
                                    element={<OnlineTesting />}
                                />
                            )}
                            {isModuleActive(Modules.ClassTests) && (
                                <Route
                                    path="/online-testing/:type/:id/:subId"
                                    element={<AutomatedTest />}
                                />
                            )}
                            {isModuleActive(Modules.ClassTests) && (
                                <Route
                                    path="/online-testing/:type/assigned/:id/:subId"
                                    element={<AutomatedTest />}
                                />
                            )}

                            {isModuleActive(Modules.ClassAttendance) && (
                                <Route path="/attendance/:id" element={<Attendance />} />
                            )}
                        </>

                        <>
                            {isModuleActive(Modules.Students) && (
                                <Route path="/all-students" element={<AllStudents />} />
                            )}
                            {isModuleActive(Modules.StudentsDuplicate) && (
                                <Route
                                    path="/all-students/duplicate-students"
                                    element={<DuplicateStudents />}
                                />
                            )}
                            {isModuleActive(Modules.StudentsUnmatched) && (
                                <Route
                                    path="/all-students/unmatched-students"
                                    element={<UnmatchedStudents />}
                                />
                            )}
                            <Route
                                path="/all-students/:id/:tab"
                                element={<Profile match="/all-students/:id/:tab" />}
                            />
                        </>
                        {isModuleActive(Modules.Reports) && (
                            <Route path="/school-reports" element={<StaffReports />} />
                        )}
                        {isModuleActive(Modules.Emails) && (
                            <Route path="/emails" element={<Emails />} />
                        )}
                        {isModuleActive(Modules.Todo) && (
                            <Route path="/to-do-list" element={<ToDoList />} />
                        )}
                        {isModuleActive(Modules.QRCodes) && (
                            <Route path="/qr-codes" element={<QRCodes />} />
                        )}
                        <Route path="/management" element={<Management />} />
                        {isModuleActive(Modules.InviteStudent) && (
                            <>
                                <Route
                                    path="/invite/student"
                                    element={<Invite inviteType="student" />}
                                />
                                <Route
                                    path="/invite/student/:id"
                                    element={<Invite inviteType="student" />}
                                />
                            </>
                        )}
                        {isModuleActive(Modules.InviteTeacher) && (
                            <>
                                <Route
                                    path="/invite/teacher"
                                    element={<Invite inviteType="teacher" />}
                                />
                                <Route
                                    path="/invite/teacher/:id"
                                    element={<Invite inviteType="teacher" />}
                                />
                            </>
                        )}
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Rollbar>
            </Layout>
            <ApiErrorModal />
        </BrowserRouter>
    );

    return content;
}

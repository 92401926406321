import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Add from 'src/components/Icons/Add';
import Pencil from 'src/components/Icons/Pencil';
import CustomLink from 'src/components/CustomLink';
import { selectWeeklyPlanCuratedHeads } from 'src/redux/weeklyPlan/selectors';
import { add } from '../../helpers';
import CustomDraggableMob from '../CustomDraggableMob/CustomDraggableMob';

const CustomDroppableMob = ({
    moveToModalData,
    setMoveToModalData,
    lessonSubName,
    lessonDays,
    column,
    columnIds,
    lessonIds,
    editedColumns,
    setEditedColumns,
    columns,
    setColumns,
    activeDay,
    setIsDirty,
    searchValue,
    lessonIndex,
    wrapperRef,
    edit,
    lessonNames,
    setEdit,
    isHomework,
    week,
    year,
    lessons,
}: any) => {
    const activeDayFromZero = activeDay - 1;
    const navigate = useNavigate();
    const params = useParams();
    const curatedHeads = useSelector(selectWeeklyPlanCuratedHeads);

    const droppableId = `${activeDayFromZero}-${lessonSubName}-${lessonDays[activeDayFromZero].columnId}-${lessonDays[activeDayFromZero].lessonId}-${lessonIndex}`;

    return (
        <Droppable droppableId={droppableId} key={activeDayFromZero}>
            {(providedDroppable) => {
                return (
                    <div
                        {...(providedDroppable.droppableProps as any)}
                        ref={providedDroppable.innerRef}
                        className="weeklyPlan-droppable"
                    >
                        <div className="weeklyPlan-droppable weeklyPlan-droppable-mobile">
                            <div className="weeklyPlan-add">
                                {isHomework && (
                                    <CustomLink
                                        to={`/weekly-plan/${params.id}/${year}/${week}/${lessons[activeDayFromZero].day}`}
                                        className="weeklyPlan-pencil weeklyPlan-add__svg-parent sc_hover_color_text_svg"
                                    >
                                        <Pencil />
                                    </CustomLink>
                                )}
                                <div
                                    className={classNames({
                                        'd-none': curatedHeads.includes(lessonSubName),
                                    })}
                                    onClick={() => {
                                        add(
                                            columns,
                                            setColumns,
                                            activeDayFromZero,
                                            {
                                                ...lessonDays[activeDayFromZero],
                                            },
                                            lessonSubName,
                                        );
                                        setIsDirty(true);
                                    }}
                                >
                                    <Add fill="#bcbcbc" />
                                </div>
                            </div>
                            {(column as any)
                                .filter((item: any) => {
                                    if (!lessonSubName) {
                                        return true;
                                    }
                                    return item.head === lessonSubName;
                                })
                                .map((item: any, indexDraggable: number) => {
                                    if (
                                        !columnIds.includes(item.columnId) ||
                                        !lessonIds.includes(item.lessonId)
                                    ) {
                                        return null;
                                    }
                                    const isCuratedHead = curatedHeads.includes(item.head);
                                    return (
                                        <CustomDraggableMob
                                            lessonIndex={lessonIndex}
                                            moveToModalData={moveToModalData}
                                            setMoveToModalData={setMoveToModalData}
                                            key={item.id}
                                            item={item}
                                            indexDraggable={indexDraggable}
                                            editedColumns={editedColumns}
                                            setColumns={setColumns}
                                            columns={columns}
                                            setIsDirty={setIsDirty}
                                            lessonNames={lessonNames}
                                            setEditedColumns={setEditedColumns}
                                            activeDay={activeDay}
                                            searchValue={searchValue}
                                            wrapperRef={wrapperRef}
                                            setEdit={setEdit}
                                            edit={edit}
                                            isCuratedHead={isCuratedHead}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                );
            }}
        </Droppable>
    );
};
export default CustomDroppableMob;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DateSelector from 'src/components/DateSelector';
import AssessmentsIcon from 'src/components/Icons/AssessmentsIcon';
import MobileControls from 'src/components/MobileControls';
import Search from 'src/components/Search';
import Table from 'src/components/Table';
import AnimatedRow from 'src/components/Table/components/AnimatedRow';
import WhiteBox from 'src/components/WhiteBox';
import { useClass } from 'src/hooks/useClass';
import { putSidebar } from 'src/redux/layout/actions';
import {
    getAssessment,
    getAssessmentStudents,
    getAssessmentTypes,
    saveAssessmentMarks,
} from 'src/redux/assessment/actions';
import SelectMenu from 'src/components/SelectMenu';
import SettingsIcon from 'src/components/Icons/Settings';
import Checkbox from 'src/components/Checkbox';
import {
    selectAssessment,
    selectAssessmentStudents,
    selectAssessmentTypes,
} from 'src/redux/assessment/selectors';
import './styles.scss';
import { DataToSave, Labels, PutAssessmentTypesProps } from 'src/redux/assessment/types';
import CommentSaveModal from 'src/components/Modals/CommentSaveModal';
import { useCallbackPrompt } from 'src/hooks/useCallbackPrompt';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import Save from 'src/components/Icons/Save';
import { defaultInputValue } from './defaultInputValue';
import RangeSelect from './RangeSelect';
import { Student } from './types';
import MobileRow from './MobileRow';

const Assessments = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const types = useSelector(selectAssessmentTypes);
    const studentsData = useSelector(selectAssessmentStudents);
    const assessments = useSelector(selectAssessment);
    const [activeType, setActiveType] = useState<PutAssessmentTypesProps>();
    const [{ week, year }, setDate] = useState({
        week: moment(new Date()).isoWeek(),
        year: moment(new Date()).year(),
    });
    const [openModal, setOpenModal] = useState(null as any);
    const [showPromptModal, setShowPromptModal] = useState(false as any);

    const [dataToSave, setDataToSave] = useState<any[]>([]);
    const [search, setSearch] = useState('');
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        !!dataToSave.length,
    ) as any;
    const { t } = useTranslation();

    useEffect(() => {
        setShowPromptModal(showPrompt);
    }, [showPrompt]);

    const students = useMemo(() => {
        return studentsData.filter(({ name }) => {
            return name.toLowerCase().includes(search.toLowerCase());
        });
    }, [studentsData, search]);

    useEffect(() => {
        if (types.length) {
            setActiveType(types[0]);
        }
    }, [types]);

    useClass();

    useEffect(() => {
        if (activeType && !!activeType.id && params) {
            dispatch(
                getAssessment({
                    classId: params.id,
                    typeId: activeType.id,
                }),
            );
        }
    }, [params?.id, activeType?.id]);

    useEffect(() => {
        dispatch(
            getAssessmentTypes({
                classId: params.id,
            }),
        );
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    const isAlreadyCommented = useCallback(
        (currentCategory: Labels, student: Student) => {
            const current = dataToSave?.find(
                (data: DataToSave) =>
                    data.label === currentCategory?.id && data.uid === student?.uid,
            );
            if (current) {
                return current.comment;
            }
            return (
                student?.labels.find(({ id }) => {
                    return id === currentCategory?.id;
                })?.comment || ''
            );
        },
        [dataToSave],
    );

    const update = (currentCategory: Labels, student: Student, { name, value }: any, cb?: any) => {
        let newState = [];
        const isAlreadyIncluded = dataToSave.find(
            ({ label, uid }) => label === currentCategory.id && uid === student.uid,
        );
        const studentData = student.labels.find(({ id }: any) => id === currentCategory.id);
        const toSave = {
            year,
            week,
            label: currentCategory.id,
            uid: student.uid,
            ...studentData,
            comment: isAlreadyCommented(currentCategory, student),
        };
        if (isAlreadyIncluded) {
            newState = [
                ...dataToSave.filter(({ label, uid }) => {
                    if (label === currentCategory.id && student.uid === uid) {
                        return false;
                    }

                    return true;
                }),
                {
                    ...isAlreadyIncluded,
                    [name]: value,
                },
            ];
        } else {
            newState = [
                ...dataToSave,
                {
                    ...toSave,
                    [name]: value,
                },
            ];
        }
        if (cb) {
            cb({
                ...toSave,
                [name]: value,
            });
        }

        setDataToSave(newState);
    };

    const onSelect = (currentCategory: Labels, student: Student) => (value: string) => {
        update(currentCategory, student, {
            name: 'value',
            value,
        });
    };

    const setCommentTextToData = (value: string) => {
        const { currentCategory, student } = openModal;
        update(
            currentCategory,
            student,
            {
                name: 'comment',
                value,
            },
            (data: any) => {
                data.value = defaultInputValue(dataToSave, currentCategory, student);
                dispatch(
                    saveAssessmentMarks({
                        classId: params.id,
                        data: [data],
                    }),
                );
            },
        );

        setOpenModal(null);
    };

    return (
        <>
            <WhiteBox>
                <div className="assessments-header">
                    <Search onChange={(text: string) => setSearch(text)} />
                    <div className="assessments-header-right">
                        {types.length > 1 && activeType && (
                            <SelectMenu
                                text={
                                    <div
                                        className="assessments-truncate"
                                        title={activeType?.name || t('assessments.openTypes')}
                                    >
                                        {activeType?.name || t('assessments.openTypes')}
                                    </div>
                                }
                                icon={<SettingsIcon />}
                                options={types.map((type) => {
                                    return {
                                        content: (
                                            <div className="assessments-option" title={type.name}>
                                                <Checkbox checked={activeType.id === type.id} />
                                                <span className="assessments-truncate">
                                                    {type.name}
                                                </span>
                                            </div>
                                        ),
                                        onClick: () => {
                                            setActiveType(type);
                                            setDataToSave([]);
                                        },
                                    };
                                })}
                            />
                        )}
                        <DateSelector
                            weekNumber={week}
                            year={year}
                            onChange={(weekNumber: number, yearNumber: number) => {
                                if (dataToSave.length) {
                                    setShowPromptModal({
                                        next: {
                                            year: yearNumber,
                                            week: weekNumber,
                                        },
                                        prev: { week, year },
                                    } as any);
                                    setDate({
                                        year: yearNumber,
                                        week: weekNumber,
                                    });
                                    return;
                                }
                                dispatch(
                                    getAssessmentStudents({
                                        classId: params.id,
                                        week: weekNumber,
                                        year: yearNumber,
                                    }),
                                );
                                setDate({
                                    week: weekNumber,
                                    year: yearNumber,
                                });
                                setDataToSave([]);
                            }}
                        />
                        <div
                            className="assessments-header-right-icon pr_hover_color_text_svg_bg"
                            onClick={() => {
                                dispatch(
                                    saveAssessmentMarks({
                                        classId: params.id,
                                        data: dataToSave,
                                        week,
                                        year,
                                        cb: () => setDataToSave([]),
                                    }),
                                );
                            }}
                        >
                            <Save />
                        </div>
                    </div>
                </div>

                <MobileControls>
                    <Search onChange={(text: string) => setSearch(text)} />
                    {types.length > 1 && activeType && (
                        <SelectMenu
                            text={
                                <div
                                    className="assessments-truncate"
                                    title={activeType?.name || t('assessments.openTypes')}
                                >
                                    {activeType?.name || t('assessments.openTypes')}
                                </div>
                            }
                            icon={<SettingsIcon />}
                            options={types.map((type) => {
                                return {
                                    content: (
                                        <div className="assessments-option" title={type.name}>
                                            <Checkbox checked={activeType.id === type.id} />
                                            <span className="assessments-truncate">
                                                {type.name}
                                            </span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        setActiveType(type);
                                        setDataToSave([]);
                                    },
                                };
                            })}
                        />
                    )}
                    <DateSelector
                        weekNumber={week}
                        year={year}
                        onChange={(weekNumber: number, yearNumber: number) => {
                            if (dataToSave.length) {
                                setShowPromptModal({
                                    next: {
                                        year: yearNumber,
                                        week: weekNumber,
                                    },
                                    prev: { week, year },
                                } as any);
                                setDate({
                                    year: yearNumber,
                                    week: weekNumber,
                                });
                                return;
                            }
                            dispatch(
                                getAssessmentStudents({
                                    classId: params.id,
                                    week: weekNumber,
                                    year: yearNumber,
                                }),
                            );
                            setDate({
                                week: weekNumber,
                                year: yearNumber,
                            });
                            setDataToSave([]);
                        }}
                    />
                    <div
                        className="assessments-header-right-icon pr_hover_color_text_svg_bg"
                        onClick={() => {
                            dispatch(
                                saveAssessmentMarks({
                                    classId: params.id,
                                    data: dataToSave,
                                    week,
                                    year,
                                    cb: () => setDataToSave([]),
                                }),
                            );
                        }}
                    >
                        <Save />
                    </div>
                </MobileControls>
                <Table
                    tableRowsClassName="assessments-table-rows"
                    header={
                        <>
                            <span className="assessments-name">{t('general.student')}</span>
                            <span className="assessments-teacher" />
                            {assessments?.categories?.map((category) => {
                                return (
                                    <span
                                        key={category.name}
                                        style={{
                                            width: `calc(( 80% - 150px ) / ${assessments?.categories.length})`,
                                        }}
                                    >
                                        {category.name}
                                    </span>
                                );
                            })}
                        </>
                    }
                    rows={students.map((student) => (
                        <>
                            <span className="assessments-name">{student.name}</span>
                            <div className="assessments-info">
                                {assessments?.categories[0]?.labels.map(({ name }, labelIndex) => {
                                    const labelLength = assessments?.categories[0]?.labels.length;
                                    return (
                                        <div key={`ukDesc_${student.uid}${labelIndex}`}>
                                            <div className="assessments-info-row">
                                                <span className="assessments-teacher">{name}</span>
                                                {assessments?.categories?.map(
                                                    (category, categoryIndex) => {
                                                        const currentCategory =
                                                            category.labels[labelIndex];
                                                        return (
                                                            <div
                                                                className="assessments-info-select"
                                                                key={`ukDescCat_${student.uid}${categoryIndex}`}
                                                                style={{
                                                                    width: `calc(( 100% - 150px ) / ${assessments?.categories.length})`,
                                                                }}
                                                            >
                                                                <RangeSelect
                                                                    currentCategory={
                                                                        currentCategory
                                                                    }
                                                                    dataToSave={dataToSave}
                                                                    onSelect={onSelect}
                                                                    student={student}
                                                                />
                                                                {defaultInputValue(
                                                                    dataToSave,
                                                                    currentCategory,
                                                                    student,
                                                                ) !== '' && (
                                                                    <div
                                                                        onClick={() => {
                                                                            setOpenModal({
                                                                                currentCategory,
                                                                                student,
                                                                            } as any);
                                                                        }}
                                                                        className="assessments-info-comment sc_hover_color_text_svg"
                                                                        title={isAlreadyCommented(
                                                                            currentCategory,
                                                                            student,
                                                                        )}
                                                                    >
                                                                        <AssessmentsIcon
                                                                            fill={
                                                                                isAlreadyCommented(
                                                                                    currentCategory,
                                                                                    student,
                                                                                )
                                                                                    ? '#0B80A4'
                                                                                    : '#54585a'
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    },
                                                )}
                                            </div>
                                            {labelIndex !== labelLength - 1 && labelLength > 1 && (
                                                <hr className="assessments-info-hr" />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    ))}
                    headerMobile={
                        <>
                            <span>{t('general.student')}</span>
                        </>
                    }
                    rowsMobile={students.map((student, i) => {
                        return {
                            head: (
                                <div className="assessments-name-mobile assessments-active">
                                    <b>{i + 1}.</b>
                                    {student.name}
                                </div>
                            ),
                            content: (
                                <>
                                    {assessments?.categories[0]?.labels.map(
                                        ({ name }, labelIndex) => {
                                            return (
                                                <div key={`ukMob_${student.uid}${labelIndex}`}>
                                                    <hr className="assessments-mobile-hr" />
                                                    <AnimatedRow
                                                        arrowLeft
                                                        head={
                                                            <div className="assessments-mobile-header">
                                                                <span>{name}</span>
                                                            </div>
                                                        }
                                                        content={assessments?.categories?.map(
                                                            (category, categoryIndex) => {
                                                                const currentCategory =
                                                                    category.labels[labelIndex];
                                                                return (
                                                                    <MobileRow
                                                                        key={categoryIndex}
                                                                        text={category.name}
                                                                        dataToSave={dataToSave}
                                                                        student={student}
                                                                        currentCategory={
                                                                            currentCategory
                                                                        }
                                                                        onSelect={onSelect}
                                                                        openModal={() =>
                                                                            setOpenModal({
                                                                                currentCategory,
                                                                                student,
                                                                            } as any)
                                                                        }
                                                                        isAlreadyCommented={isAlreadyCommented(
                                                                            currentCategory,
                                                                            student,
                                                                        )}
                                                                    />
                                                                );
                                                            },
                                                        )}
                                                    />
                                                </div>
                                            );
                                        },
                                    )}
                                </>
                            ),
                        };
                    })}
                />
            </WhiteBox>
            <CommentSaveModal
                open={!!openModal}
                comment={isAlreadyCommented(openModal?.currentCategory, openModal?.student)}
                onSave={(text: string) => setCommentTextToData(text)}
                onClose={() => {
                    setOpenModal(null);
                }}
            />
            <ConfirmModal
                text={t('general.unsavedChangesConfirmation')}
                onClick={() => {
                    if (typeof showPromptModal === 'object') {
                        setDate(showPromptModal.next);
                        dispatch(
                            getAssessmentStudents({
                                classId: params.id,
                                ...showPromptModal.next,
                            }),
                        );
                        setShowPromptModal(false);
                        setDataToSave([]);
                        return;
                    }
                    confirmNavigation();
                }}
                onClose={() => {
                    if (typeof showPromptModal === 'object') {
                        setDate(showPromptModal.prev);
                        setShowPromptModal(false);
                        return;
                    }
                    cancelNavigation();
                }}
                open={showPromptModal}
            />
        </>
    );
};

export default Assessments;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import { deleteStudentTutorial, getStudentTutorials } from 'src/redux/students/actions';
import { selectStudentTutorials } from 'src/redux/students/selectors';
import ActionText from 'src/components/ActionText';
import Remove from 'src/components/Icons/Remove';
import '../styles.scss';
import SuccessModal from 'src/components/Modals/SuccessModal';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { appConstants } from 'src/constants';
import { useAddLeftBorderToTableHeader } from '../../helpers';

const ProfileInfoTutorialsModal = ({ open, onClose }: { open: any; onClose: any }) => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const items = useSelector(selectStudentTutorials);
    const [openTutorialDeleteSuccessModal, setOpenTutorialDeleteSuccessModal] = useState(false);
    const { addScroll } = useAddLeftBorderToTableHeader();
    const [openConfirmModal, setOpenConfirmModal] = useState(null as any);

    useEffect(() => {
        addScroll();
    }, [items]);

    useEffect(() => {
        if (open) {
            dispatch(getStudentTutorials({ dataType: 'tutorials', studentId: open }));
        }
    }, [open]);

    if (!open) {
        return <></>;
    }

    return (
        <Modal
            disableOutsideClick={openConfirmModal}
            open={open}
            onClose={() => onClose()}
            noPadding
        >
            <div className="profileInfoTableModal tutorials">
                <div className="profileInfoTableModal-title">{t('profile.tutorials')}</div>
                <Table
                    tableRowsClassName="profileInfoTableModal-table"
                    header={<span>{t('profile.pastTutorials')}</span>}
                    rows={items.map((oneResult: any) => {
                        return (
                            <React.Fragment key={oneResult.id}>
                                <span>{moment.unix(oneResult.timestamp).format()}</span>
                                <ActionText
                                    onClick={() =>
                                        window.open(
                                            `${appConstants.server}/study/tutorials/print/${open}/${oneResult.timestamp}`,
                                            '_blank',
                                        )
                                    }
                                >
                                    {t('general.view').toUpperCase()}
                                </ActionText>
                                <span
                                    onClick={() => {
                                        setOpenConfirmModal(oneResult);
                                    }}
                                    className="sc_hover_color_text_svg cursor-pointer"
                                >
                                    <Remove />
                                </span>
                            </React.Fragment>
                        );
                    })}
                />
                <ConfirmModal
                    open={openConfirmModal}
                    text={t('profile.pastTutorials')}
                    onClick={() => {
                        dispatch(
                            deleteStudentTutorial({
                                studentId: params.id as string,
                                tutorialId: openConfirmModal.id,
                                cb: () => {
                                    setOpenTutorialDeleteSuccessModal(true);
                                },
                            }),
                        );
                    }}
                    onClose={() => {
                        setOpenConfirmModal(null);
                    }}
                />
                <SuccessModal
                    lottie
                    open={openTutorialDeleteSuccessModal}
                    text={t('profile.confirmTutorialDeletion')}
                    onClose={() => {
                        setOpenTutorialDeleteSuccessModal(false);
                        onClose();
                    }}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoTutorialsModal;

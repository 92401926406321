import { Dispatch, SetStateAction } from 'react';
import Arrow from 'src/components/Arrow';
import ELearningInfoData from 'src/components/ELearningInfoData';
import NoResults from 'src/components/NoResults';
import { ELearningType } from 'src/redux/weeklyPlan/types';
import { useTranslation } from 'react-i18next';

const NewAssignmentsELearningInfo = ({
    num,
    eLearning,
    setNum,
}: {
    num: number;
    eLearning: ELearningType[];
    setNum: Dispatch<SetStateAction<number>>;
}) => {
    const arrowLeftActive = num >= 1;
    const arrowRightActive = num < eLearning.length - 1;
    const { t } = useTranslation();

    if (eLearning[num]) {
        return (
            <div className="NewAssignmentsEL-activity-info">
                <div className="NewAssignmentsEL-activity-selector">
                    <div className="NewAssignmentsEL-activity-selector-buttons">
                        <Arrow
                            onClick={() => {
                                if (arrowLeftActive) {
                                    setNum(num - 1);
                                }
                            }}
                            direction="left"
                            disabled={!arrowLeftActive}
                        />
                        <span className="NewAssignmentsEL-activity-selector-buttons__counter">
                            {t('NewAssigment.activityCount', {
                                current: num + 1,
                                total: eLearning.length,
                            })}
                        </span>
                        <Arrow
                            onClick={() => {
                                if (arrowRightActive) {
                                    setNum(num + 1);
                                }
                            }}
                            direction="right"
                            disabled={!arrowRightActive}
                        />
                    </div>
                </div>
                <div className="NewAssignmentsEL-activity-info-data">
                    <ELearningInfoData
                        taskTime={eLearning[num].time}
                        taskLevel={eLearning[num].level}
                        taskPreviewUrl={`${eLearning[num].preview}`}
                        taskOup={eLearning[num].oup}
                    />
                </div>
            </div>
        );
    }
    return <NoResults title={t('NewAssigment.noResults')} />;
};
export default NewAssignmentsELearningInfo;
